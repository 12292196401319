import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Link,
  Menu,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

export const StyledMenuButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.3rem 2rem;
  cursor: pointer;

  svg {
    width: 2.2rem;
    height: 2.2rem;
  }

  label {
    cursor: pointer;
    line-height: 1.3125rem;
    font-size: 1.3rem;
    letter-spacing: 0.125rem;
    color: ${({ theme }) => theme.colors.grayLight};
  }

  &:hover {
    label {
      text-decoration: underline;
    }
  }
`;

export const StyledLoginMenu = styled(Menu)`
  && {
    margin-top: 1.5rem;
    .MuiPaper-root {
      border-radius: 0.5rem;
    }
  }
`;

export const StyledFormContainer = styled(Box)`
  display: flex;
  flex-direction: columns;
  width: 32rem;
  height: auto;
  align-content: center;
  padding: 1rem 2rem;
`;

export const StyledForm = styled.form`
  display: flex;
  width: 100%;
  padding: 1rem;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  gap: 2rem;
`;

export const StyledTitleContainer = styled(Box)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    margin-bottom: 1rem;
  }
`;

export const StyledTitle = styled(Typography)`
  && {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    variant: h4;
    font-weight: 500;
    font-size: 1.75rem;
    align-self: center;
    line-height: 2.0525rem;
    font-family: 'Work Sans', sans-serif;
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  align-self: center;
  justify-self: center;
`;

export const StyledAlert = styled(Alert)`
  && {
    align-content: center;
    justify-content: center;
    font-size: 1.2rem;
    line-height: 2.0525rem;
    font-family: 'Work Sans', sans-serif;

    .MuiAlert-action {
      align-items: center;
      justify-content: center;
    }
    .MuiAlert-icon {
      align-items: center;
      justify-content: center;
    }
  }
`;

export const StyledCenterBlackLink = styled(Link)`
  && {
    text-align: center;
    text-decoration: underline;
    color: #000000;
    height: 400;
    font-size: 1.125rem;
    line-height: 1.3rem;
  }
`;

export const StyledRedLink = styled(Link)`
  && {
    color: #d71515;
    text-decoration: underline;
    height: 400;
    font-size: 1.125rem;
    line-height: 1.3rem;
  }
`;

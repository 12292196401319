import React from 'react';
import { useMap } from 'react-leaflet';

function MoveButtons() {
  const map = useMap();

  const handleMoveLeft = () => {
    map.panBy([-200, 0]);
  };

  const handleMoveRight = () => {
    map.panBy([200, 0]);
  };

  return (
    <div
      style={{
        position: 'absolute',
        bottom: '260px',
        right: '10px',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'row',
        gap: 'auto',
      }}
    >
      <button
        onClick={handleMoveLeft}
        style={{
          backgroundColor: 'white',
          color: 'black',
          border: '1px solid #D9D9D9',
          borderTopLeftRadius: '6px',
          borderBottomLeftRadius: '6px',
          width: '30px',
          height: '30px',
          cursor: 'pointer',
        }}
      >
        &lt;
      </button>
      <button
        onClick={handleMoveRight}
        style={{
          backgroundColor: 'white',
          color: 'black',
          border: '1px solid #D9D9D9',
          borderTopRightRadius: '6px',
          borderBottomRightRadius: '6px',
          width: '30px',
          height: '30px',
          cursor: 'pointer',
        }}
      >
        &gt;
      </button>
    </div>
  );
}

export default MoveButtons;

export const geoLayers = [
  [
    'Informações Adicionais',
    [
      'Rede Gasoduto (Aço)',
      'Rede Gasoduto (PEAD)',
      'Rede Gasoduto (Cobre Revestido)',
      'Consumo de Energia Elétrica 2022 (Mwh)',
      'Empresas com resíduos para Amônia',
      'Empresas com resíduos para Hidrogênio',
      'Empresas com resíduos para Biomassa',
      'Aterros Sanitários',
      'Produto Interno Bruto (PIB) - Municipios Pernambuco 2021',
      'Áreas Urbanas (Cidades e Vilas)',
      'Bases de estações Fluviométricas',
      'Bases de estações Pluviométricas',
      'Bases de estações Anemométricas - AMA',
      'Bases de estações Anemométricas - SONDA',
      'Rede CELPE - Alta Tensão',
      'Rede CELPE - Baixa Tensão',
      'Rede CELPE - Média Tensão',
      'Rede CELPE - Estrutura de Suporte',
    ],
  ],
  ['SUAPE', ['Área total do Porto', 'Porto Organizado']],
  ['Unidades Administrativas', ['Municípios']],
  [
    'Climatologia',
    [
      'Insolação (horas)',
      'Precipitação',
      'Temperatura Média Anual (INMET)',
      'Umidade Relativa do Ar',
    ],
  ],
  [
    'Potencial eólico',
    [
      'Velocidade do Vento (10m_s)',
      'Velocidade do Vento (50m_s)',
      'Velocidade do Vento (100m_s)',
      'Velocidade do Vento (150m_s)',
      'Velocidade do Vento (200m_s)',
    ],
  ],
  [
    'Sistema Elétrico Existente',
    [
      'Usina Fotovoltáicas Existentes',
      'Usina de Combustível Fóssil (Termelétricas)',
      'Centrais Geradoras Hidrelétricas',
      'Linhas de Transmissão Existentes',
      'Subestações Existentes',
      'Parques Eólicos Existentes',
      'Usinas Hidrelétricas Existentes',
    ],
  ],
  [
    'Sistema Elétrico Planejado',
    [
      'Parques Eólicos Planejados',
      'Linhas de Transmissão Planejadas',
      'Subestações Planejadas',
      'Usinas Fotovoltáicas Planejadas',
    ],
  ],
  [
    'Bases de combustíveis',
    [
      'Petróleo e derivados',
      'Regasificação Gás Natural Liquefeito (GNL)',
      'Gás Liquefeito de Petróleo (GLP)',
      'Exploração e Produção de Hidrocarbonetos',
      'Bases de Combustíveis',
      'Plantas de Etanol',
    ],
  ],
  [
    'Socioambiental',
    [
      'Unidades de Conservação Ambiental',
      'Territórios quilombolas',
      'Região Semiárida (Poligono das Secas)',
      'Regiões de importância Biológica (Caatinga)',
      'Área prioritária para Conservação da Mata Atlântica',
      'Unidades Agrícolas Instaladas pelo INCRA',
      'Terras indígenas (Homologadas)',
      'Reserva da Biosfera',
      'Sítios Arqueológicos',
      'Aves Migratórias (Áreas Ameçadas)',
      'Aves Migratórias (Áreas de concentração)',
      'Cavernas',
      'Unidades Territoriais Urbanas',
      'Zonemaneto Agroecológico',
    ],
  ],
  [
    'Infraestrutura de transportes',
    [
      'Ferrovias Planejadas',
      'Pátios Ferroviários',
      'Gasodutos de Distribuição',
      'Gasodutos de Transporte',
      'Rodovias Federais',
      'Rodovias Municipais',
      'Rodovias Estaduais',
      'Aerôdromo e Heliponto',
      'Aeroportos',
    ],
  ],
  [
    'Potencial Solar',
    [
      'Irradiação Global Horizontal Anual (Wh/m².dia)',
      'Irradiação Difusa Anual (Wh/m².dia)',
      'Irradiação Direta Normal Anual (Wh/m².dia)',
      'Irradiação no Plano Inclinado Anual (Wh/m².dia)',
      'Irradiação PAR (Photosynthetically-active radiation) (Wh/m².dia)',
      'Irradiação Média Global no Inverno (Março a agosto)',
    ],
  ],
];

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.featureInfo,
table.featureInfo td,
table.featureInfo th {
  border: 1px solid #fff;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  font-size: 90%;
  font-family: 'Work Sans';
  padding: 0.2em 0.1em;
}
table.featureInfo th {
  padding: 0.2em 0.2em;
  font-weight: bold;
  background: white;
  font-family: 'Work Sans';
}
table.featureInfo td {
  background: white;
  font-family: 'Work Sans';
}
table.featureInfo tr.odd td {
  background: white;
}
table.featureInfo caption {
  text-align: left;
  font-size: 100%;
  font-weight: bold;
  padding: 0.2em 0.2em;
  font-family: 'Work Sans';
}
`, "",{"version":3,"sources":["webpack://./src/Components/RenderAttributeTable/tableStyle.css"],"names":[],"mappings":"AAAA;;;EAGE,sBAAsB;EACtB,yBAAyB;EACzB,SAAS;EACT,UAAU;EACV,cAAc;EACd,wBAAwB;EACxB,oBAAoB;AACtB;AACA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,wBAAwB;AAC1B","sourcesContent":["table.featureInfo,\ntable.featureInfo td,\ntable.featureInfo th {\n  border: 1px solid #fff;\n  border-collapse: collapse;\n  margin: 0;\n  padding: 0;\n  font-size: 90%;\n  font-family: 'Work Sans';\n  padding: 0.2em 0.1em;\n}\ntable.featureInfo th {\n  padding: 0.2em 0.2em;\n  font-weight: bold;\n  background: white;\n  font-family: 'Work Sans';\n}\ntable.featureInfo td {\n  background: white;\n  font-family: 'Work Sans';\n}\ntable.featureInfo tr.odd td {\n  background: white;\n}\ntable.featureInfo caption {\n  text-align: left;\n  font-size: 100%;\n  font-weight: bold;\n  padding: 0.2em 0.2em;\n  font-family: 'Work Sans';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Box, Link } from '@mui/material';
import styled from 'styled-components';

export const StyledMain = styled(Box)`
  && {
    margin: 6.5rem auto;
    padding: 2rem 15vw;
    witdh: 100vw;
    height: auto;
  }
`;

export const StyledMetadataMenu = styled(Box)`
  && {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    padding: 1rem 0rem;
  }
`;

export const StyledBackLink = styled(Link)`
  && {
    cursor: pointer;
    color: #000000;
    .MuiSvgIcon-root {
      width: 2.75rem;
      height: 2.75rem;
    }
  }
`;

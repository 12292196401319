import { Alert, Snackbar } from '@mui/material';
import React, { ReactNode, createContext, useState } from 'react';

interface AlertProviderProps {
  children: ReactNode;
}
interface AlertContextProps {
  setAlertMessage: (
    newSeverity: 'success' | 'warning' | 'error' | 'info',
    newMessage: string
  ) => void;
}

export const AlertContext = createContext<AlertContextProps>(
  {} as AlertContextProps
);

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<{
    message: string | null;
    severity: 'success' | 'warning' | 'error' | 'info';
  }>({ message: 'Seja bem vindo!', severity: 'success' });
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const setAlertMessage = (
    newSeverity: 'success' | 'warning' | 'error' | 'info',
    newMessage: string
  ) => {
    setAlert({ severity: newSeverity, message: newMessage });
    setOpen(true);
  };

  return (
    <AlertContext.Provider value={{ setAlertMessage }}>
      {children}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};

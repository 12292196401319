import styled from 'styled-components';
import { Box, Typography } from '@mui/material';

export const EnergyBox = styled(Box)`
  && {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#F8F9FA',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  }
`;

export const EnergyTypographyTitle = styled(Typography)`
  color: #015c53;
  font-weight: bold !important;
  font-size: 12px !important;
  margin: 0;
`;

export const EnergyTypographyData = styled(Typography)`
  color: #00000;
  font-weight: bold;
  font-size: 12px !important;
  margin: 0;
`;

import React, { useContext, useState } from 'react';
import { FormTextField, FormContainer, FormTitle } from '../../Components';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LOGIN_ROUTE } from '../../global/constants/routes/defaultConstants';
import { ApiRegisterUser } from '../../services/api';
import { UserType } from '../../global/types/user';
import { AlertContext } from '../../context/AlertContext';

const SignupValidationSchema = yup.object({
  email: yup
    .string()
    .email('Entre com um email válido')
    .required('O email é nescessário'),
  name: yup.string().required('O seu nome é obrigatório'),
  institution: yup.string(),
  role: yup.string(),
  password: yup.string().required('A senha é necessária'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'As senhas precisam ser iguais')
    .required('A confimação de senha é necessária'),
});

export const SignUp = () => {
  const [, setLoading] = useState<boolean>(false);
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();

  const handleRegisterUser = async (values: UserType) => {
    setLoading(true);
    try {
      const { message } = await ApiRegisterUser(values);
      await setAlertMessage('success', `${message}, Verifique seu email!`);
      navigate(LOGIN_ROUTE);
    } catch (error) {
      console.log('🚀 ~ handleRegisterUser ~ error:', error);
      setAlertMessage('error', (error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const signupForm = useFormik({
    initialValues: {
      email: '',
      institution: '',
      role: '',
      password: '',
      name: '',
      paymentId: null,
      profiles: [1],
      confirmPassword: '',
    },
    validationSchema: SignupValidationSchema,
    onSubmit: handleRegisterUser,
  });

  const handleBackClick = () => {
    window.location.href = LOGIN_ROUTE;
  };

  return (
    <FormContainer
      onSubmit={signupForm.handleSubmit}
      onBack={handleBackClick}
      submitLabel="Cadastrar"
    >
      <FormTitle>Cadastro</FormTitle>
      <FormTextField
        standartLabel="E-mail"
        placeholder="abcdef@exemplo.com"
        type="email"
        name="email"
        value={signupForm.values.email}
        onChange={signupForm.handleChange}
        onBlur={signupForm.handleBlur}
        error={signupForm.touched.email && Boolean(signupForm.errors.email)}
        helperText={signupForm.touched.email && signupForm.errors.email}
        required
      />

      <FormTextField
        standartLabel="Nome"
        placeholder="Digite seu nome."
        name="name"
        value={signupForm.values.name}
        onChange={signupForm.handleChange}
        onBlur={signupForm.handleBlur}
        error={signupForm.touched.name && Boolean(signupForm.errors.name)}
        helperText={signupForm.touched.name && signupForm.errors.name}
        required
      />
      <FormTextField
        standartLabel="Instituição"
        placeholder="Digite a instituição que você pertence."
        name="institution"
        value={signupForm.values.institution}
        onChange={signupForm.handleChange}
        onBlur={signupForm.handleBlur}
        error={
          signupForm.touched.institution &&
          Boolean(signupForm.errors.institution)
        }
        helperText={
          signupForm.touched.institution && signupForm.errors.institution
        }
      />

      <FormTextField
        standartLabel="Função"
        placeholder="Digite a função que você atua na instituição."
        name="role"
        value={signupForm.values.role}
        onChange={signupForm.handleChange}
        onBlur={signupForm.handleBlur}
        error={signupForm.touched.role && Boolean(signupForm.errors.role)}
        helperText={signupForm.touched.role && signupForm.errors.role}
      />
      <FormTextField
        standartLabel="Senha"
        placeholder="Digite uma senha de login."
        type="password"
        name="password"
        value={signupForm.values.password}
        onChange={signupForm.handleChange}
        onBlur={signupForm.handleBlur}
        error={
          signupForm.touched.password && Boolean(signupForm.errors.password)
        }
        helperText={signupForm.touched.password && signupForm.errors.password}
        required
      />
      <FormTextField
        standartLabel="Confirmar senha"
        placeholder="Repita a senha de login."
        type="password"
        name="confirmPassword"
        value={signupForm.values.confirmPassword}
        onChange={signupForm.handleChange}
        onBlur={signupForm.handleBlur}
        error={
          signupForm.touched.confirmPassword &&
          Boolean(signupForm.errors.confirmPassword)
        }
        helperText={
          signupForm.touched.confirmPassword &&
          signupForm.errors.confirmPassword
        }
        required
      />
    </FormContainer>
  );
};

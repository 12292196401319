import React, { useEffect, useState } from 'react';
import { StyledMain, StyledBackLink, StyledMetadataMenu } from '../styles';
import { Footer } from '../../../Components';
import { HomeContainer } from '../../styles';
import {
  StyledMetadataContainer,
  StyledMetadataHighlightsContainer,
  StyledMetadataHighlightsDownloadButton,
  StyledMetadataHighlightsImage,
  StyledMetadataTextualContainer,
  StyledMetadataTextualSummary,
  StyledMetadataTextualItem,
  StyledMetadataTextualItemTitle,
  StyledMetadataTextualItemValue,
  StyledMetadataTextualTitle,
} from './styles';
import { StyledItemImg } from '../components/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useParams } from 'react-router-dom';
import { useAxios } from '../../../hooks/useAxios';
import { Loading } from '../components/Loading';
import { API_DETAIL_METADATA_ROUTE } from '../../../global/constants/routes/apiConstants';
import { getFormattedAuthorizationToken } from '../../../global/storage/auth';
import { METADATA_ROUTE } from '../../../global/constants/routes/metadataConstants';

export type MetadataProps = {
  id: string;
  imagem: string;
  titulo: string;
  dataDivulgacao: string;
  dataAtualizacao: string;
  resumo: string;
  responsavel: string;
  categoriaTematica: string;
  idiomaCamada: string;
  formatoArquivo: string;
  sistemaCoordenadas: string;
  statusDesenvolvimento: string;
};

export const MetadataDetail: React.FC = () => {
  const { pk } = useParams<{ pk: string }>();
  const [metadata, setMetadata] = useState<MetadataProps | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchMetadata = async () => {
    try {
      const token = getFormattedAuthorizationToken();

      const response = await fetch(
        `https://api.dev.atlas.isitics.com/metadata/${pk}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }
      );

      if (!response.ok) {
        const errorResponse = await response.text();
        console.error('Erro na resposta da API:', errorResponse);
        throw new Error(`Erro na resposta da API: ${response.statusText}`);
      }

      const data = await response.json();
      console.log(data.content);
      const formatedData = {
        id: data.content.id.toString(),
        titulo: data.content.title,
        dataDivulgacao: new Date(
          data.content.release_date
        ).toLocaleDateString(),
        dataAtualizacao: new Date(
          data.content.update_date
        ).toLocaleDateString(),
        resumo: data.content.resume,
        idiomaCamada: data.content.language,
        responsavel: data.content.responsible,
        statusDesenvolvimento: data.content.status,
        imagem: data.content.url_image,
        sistemaCoordenadas: data.content.coordinate_system,
        categoriaTematica: data.content.thematic_category,
        formatoArquivo: data.content.file_format,
      };
      console.log('imagem: ', formatedData.imagem);

      setMetadata(formatedData);
    } catch (error) {
      console.error('Erro ao carregar metadados:', error);
      setError('Erro ao carregar metadados');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMetadata();
  }, []);

  if (loading) return <Loading />;
  if (error) return <div>{error}</div>;

  const handleBackClick = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = METADATA_ROUTE;
    }
  };
  // window.location.href
  console.log('metadata: ', metadata);
  console.log(window.location.host);
  return (
    <HomeContainer>
      <StyledMain>
        <StyledMetadataMenu>
          <StyledBackLink onClick={handleBackClick}>
            <ChevronLeftIcon fontSize="large" />
          </StyledBackLink>
        </StyledMetadataMenu>
        {metadata && (
          <StyledMetadataContainer>
            <StyledMetadataTextualContainer>
              <StyledMetadataTextualTitle variant="h1">
                {metadata.titulo}
              </StyledMetadataTextualTitle>
              <StyledMetadataTextualSummary>
                {metadata.resumo}
              </StyledMetadataTextualSummary>
              <StyledMetadataTextualItem>
                <StyledMetadataTextualItemTitle>
                  Responsável
                </StyledMetadataTextualItemTitle>
                <StyledMetadataTextualItemValue>
                  {metadata.responsavel}
                </StyledMetadataTextualItemValue>
              </StyledMetadataTextualItem>
              <StyledMetadataTextualItem>
                <StyledMetadataTextualItemTitle>
                  Data de divulgação
                </StyledMetadataTextualItemTitle>
                <StyledMetadataTextualItemValue>
                  {metadata.dataDivulgacao}
                </StyledMetadataTextualItemValue>
              </StyledMetadataTextualItem>
              <StyledMetadataTextualItem>
                <StyledMetadataTextualItemTitle>
                  Última atualização
                </StyledMetadataTextualItemTitle>
                <StyledMetadataTextualItemValue>
                  {metadata.dataAtualizacao}
                </StyledMetadataTextualItemValue>
              </StyledMetadataTextualItem>
              <StyledMetadataTextualItem>
                <StyledMetadataTextualItemTitle>
                  Status
                </StyledMetadataTextualItemTitle>
                {metadata.statusDesenvolvimento == 'Concluído' && (
                  <StyledMetadataTextualItemValue
                    style={{
                      background: '#c8ff9d',
                      border: '2px solid #c8ff9d',
                    }}
                  >
                    {metadata.statusDesenvolvimento}
                  </StyledMetadataTextualItemValue>
                )}
                {metadata.statusDesenvolvimento == 'Em Andamento' && (
                  <StyledMetadataTextualItemValue
                    style={{
                      background: '#FFECA4',
                      border: '2px solid #FFECA4',
                    }}
                  >
                    {metadata.statusDesenvolvimento}
                  </StyledMetadataTextualItemValue>
                )}
                {metadata.statusDesenvolvimento == 'Contínuo' && (
                  <StyledMetadataTextualItemValue
                    style={{
                      background: '#D3B0FF',
                      border: '2px solid #D3B0FF',
                    }}
                  >
                    {metadata.statusDesenvolvimento}
                  </StyledMetadataTextualItemValue>
                )}
              </StyledMetadataTextualItem>
              <StyledMetadataTextualItem>
                <StyledMetadataTextualItemTitle>
                  Idioma
                </StyledMetadataTextualItemTitle>
                <StyledMetadataTextualItemValue>
                  {metadata.idiomaCamada}
                </StyledMetadataTextualItemValue>
              </StyledMetadataTextualItem>
              <StyledMetadataTextualItem>
                <StyledMetadataTextualItemTitle>
                  Categoria Temática
                </StyledMetadataTextualItemTitle>
                <StyledMetadataTextualItemValue>
                  {metadata.categoriaTematica}
                </StyledMetadataTextualItemValue>
              </StyledMetadataTextualItem>
              <StyledMetadataTextualItem>
                <StyledMetadataTextualItemTitle>
                  Sistema de Coordenadas
                </StyledMetadataTextualItemTitle>
                <StyledMetadataTextualItemValue>
                  {metadata.sistemaCoordenadas}
                </StyledMetadataTextualItemValue>
              </StyledMetadataTextualItem>
              <StyledMetadataTextualItem>
                <StyledMetadataTextualItemTitle>
                  Formato de Arquivo
                </StyledMetadataTextualItemTitle>
                <StyledMetadataTextualItemValue>
                  {metadata.formatoArquivo}
                </StyledMetadataTextualItemValue>
              </StyledMetadataTextualItem>
            </StyledMetadataTextualContainer>
            <StyledMetadataHighlightsContainer>
              <StyledMetadataHighlightsImage
                src={metadata.imagem}
                alt={metadata.titulo}
                // alt={metadata.titulo}
              />

              <StyledMetadataHighlightsDownloadButton
                rel="noopener noreferrer"
                // href={metadata.linkDownload}
                target="_blank"
              >
                BAIXAR METADADO
              </StyledMetadataHighlightsDownloadButton>
            </StyledMetadataHighlightsContainer>
          </StyledMetadataContainer>
        )}
      </StyledMain>
      <Footer></Footer>
    </HomeContainer>
  );
};

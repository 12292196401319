import React, { useContext } from 'react';
import { FormContainer, FormTextField, FormTitle } from '../../Components';
import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE } from '../../global/constants/routes/defaultConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AlertContext } from '../../context/AlertContext';
import { ApiSendPasswordRecoveryEmail } from '../../services/api';
import { SendPasswordRecoveryEmailType } from '../../global/types/user';

const SendPasswordRecoveryEmailSchema = yup.object({
  email: yup
    .string()
    .email('Entre com um email válido')
    .required('O email é nescessário'),
});

export const SendPasswordRecoveryEmail: React.FC = () => {
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();

  const handleSendPasswordRecoveryEmail = async (
    values: SendPasswordRecoveryEmailType
  ) => {
    try {
      const { message } = await ApiSendPasswordRecoveryEmail(values);
      await setAlertMessage('success', message);
      navigate(LOGIN_ROUTE);
    } catch (error) {
      setAlertMessage('error', (error as Error).message);
    }
  };

  const SendPasswordRecoveryEmailForm = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: SendPasswordRecoveryEmailSchema,
    onSubmit: handleSendPasswordRecoveryEmail,
  });

  const handleBackClick = () => {
    window.location.href = LOGIN_ROUTE;
  };

  return (
    <FormContainer
      onSubmit={SendPasswordRecoveryEmailForm.handleSubmit}
      onBack={handleBackClick}
      submitLabel="Confimar"
    >
      <FormTitle>Para recuperar sua senha digite o email da conta.</FormTitle>
      <FormTextField
        standartLabel="E-mail"
        placeholder="abcdef@exemplo.com"
        type="email"
        name="email"
        value={SendPasswordRecoveryEmailForm.values.email}
        onChange={SendPasswordRecoveryEmailForm.handleChange}
        onBlur={SendPasswordRecoveryEmailForm.handleBlur}
        error={
          SendPasswordRecoveryEmailForm.touched.email &&
          Boolean(SendPasswordRecoveryEmailForm.errors.email)
        }
        helperText={
          SendPasswordRecoveryEmailForm.touched.email &&
          SendPasswordRecoveryEmailForm.errors.email
        }
        required
      />
    </FormContainer>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-bottom {
  position: absolute;
  z-index: 1004;
}

.info-legend {
  z-index: 1003 !important;
}

/* .info-legend::-webkit-scrollbar {
  width: 14px;
}
.info-legend::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}
.info-legend::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
} */
`, "",{"version":3,"sources":["webpack://./src/Components/LegendComponent/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;;;;;;;;;GASG","sourcesContent":[".leaflet-bottom {\n  position: absolute;\n  z-index: 1004;\n}\n\n.info-legend {\n  z-index: 1003 !important;\n}\n\n/* .info-legend::-webkit-scrollbar {\n  width: 14px;\n}\n.info-legend::-webkit-scrollbar-thumb {\n  background-color: rgba(0, 0, 0, 0.16);\n  border-radius: 5px;\n}\n.info-legend::-webkit-scrollbar-thumb:hover {\n  background-color: rgba(0, 0, 0, 0.4);\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

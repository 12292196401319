import React, { useContext } from 'react';
import {
  FormTextField,
  FormTitle,
  FormContainer,
  // FormCenteredBlackLink,
  FormRedLink,
} from '../../Components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ApiLogin } from '../../services/api';
import { CredentialsType } from '../../global/types/user';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from '../../global/constants/routes/defaultConstants';
import { AlertContext } from '../../context/AlertContext';
import {
  RECOVERY_PASSWORD_ROUTE,
  REGISTER_ROUTE,
} from '../../global/constants/routes/userConstants';

const loginValidationSchema = yup.object({
  email: yup
    .string()
    .email('Entre com um email válido')
    .required('O email é nescessário'),
  password: yup.string().required('A senha é necessária'),
});

export const Login: React.FC = () => {
  // const [isLoading, setLoading] = useState<boolean>(false);
  const { login } = useContext(AuthContext);
  const { setAlertMessage } = useContext(AlertContext);

  const navigate = useNavigate();
  const handleLogin = async (values: CredentialsType) => {
    try {
      const { message } = await ApiLogin(values, login);
      await setAlertMessage('success', message);
      navigate(HOME_ROUTE);
    } catch (error) {
      setAlertMessage('error', (error as Error).message);
    } finally {
    }
  };

  const loginForm = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit: handleLogin,
  });

  // const RegisterButtonLink = (
  //   <FormCenteredBlackLink href={REGISTER_ROUTE}>
  //     Cadastre-se
  //   </FormCenteredBlackLink>
  // );

  return (
    <FormContainer
      onSubmit={loginForm.handleSubmit}
      submitLabel="Entrar"
      // formContainerFooter={RegisterButtonLink}
    >
      <FormTitle>Login</FormTitle>
      <FormTextField
        standartLabel="E-mail"
        placeholder="abcdef@exemplo.com"
        type="email"
        name="email"
        value={loginForm.values.email}
        onChange={loginForm.handleChange}
        onBlur={loginForm.handleBlur}
        error={loginForm.touched.email && Boolean(loginForm.errors.email)}
        helperText={loginForm.touched.email && loginForm.errors.email}
        required
      />
      <FormTextField
        standartLabel="Senha"
        placeholder="Digite uma senha de login."
        type="password"
        name="password"
        value={loginForm.values.password}
        onChange={loginForm.handleChange}
        onBlur={loginForm.handleBlur}
        error={loginForm.touched.password && Boolean(loginForm.errors.password)}
        helperText={loginForm.touched.password && loginForm.errors.password}
        required
      />
      <FormRedLink href={RECOVERY_PASSWORD_ROUTE()}>
        Esqueci a senha
      </FormRedLink>
    </FormContainer>
  );
};

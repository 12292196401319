import styled, { css } from 'styled-components';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { Checkbox, Box } from '@mui/material';

export const StyledCheckbox = styled(Checkbox)`
  ${({ theme }) => css`
    &.MuiButtonBase-root {
      color: ${theme.colors.grayPale} !important;

      &:before {
        content: '';
        position: absolute;
        top: 33%;
        border-radius: 2px;
        background: ${theme.colors.grayPale};
        padding: 7px;
      }
    }

    &.Mui-checked {
      color: ${theme.colors.greenLight} !important;

      &:before {
        content: '';
        position: absolute;
        top: 33%;
        border-radius: 2px;
        background: ${theme.colors.greenLight};
        padding: 7px;
      }
    }

    .MuiSvgIcon-root {
      font-size: 2rem;
    }
  `}
`;

export const IconGroup = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-right: 1rem;
`;

export const TreeViewContainer = styled(TreeView)`
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.grayPale};
  }

  .MuiTreeItem-content {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .MuiTreeItem-label {
    width: unset !important;
  }

  .MuiTypography-root {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 1.3rem;
  }

  .active {
    color: ${({ theme }) => theme.colors.greenLight};
  }

  .MuiCollapse-root .MuiTreeItem-label {
    width: 100% !important;
  }

  .MuiTreeItem-iconContainer svg {
    font-size: 2.4rem !important;
  }

  .MuiCollapse-root .MuiTreeItem-iconContainer {
    display: none !important;
  }
`;

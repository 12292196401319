import React from 'react';
import { MetadataPreviewProps } from './MetadataItemComponent';
import MetadataListComponent from './MetadataListComponent';
import {
  StyledBoxListMetadataByCategory,
  StyledBoxListMetadataByCategoryTitle,
  StyledCategoryLink,
} from './styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { NavLink } from 'react-router-dom';

export type CategoriesWithMetadataType = {
  tituloCategoria: string;
  listaMetadados: MetadataPreviewProps[];
};

export type ListMetadataByCategoryComponentProps = {
  categories: CategoriesWithMetadataType[];
};

const ListMetadataByCategoryComponent: React.FC<
  ListMetadataByCategoryComponentProps
> = ({ categories }) => {
  return (
    <>
      {Array.isArray(categories) &&
        categories.map(
          (categoria: CategoriesWithMetadataType, index: number) => {
            return (
              <StyledBoxListMetadataByCategory key={index}>
                <StyledBoxListMetadataByCategoryTitle>
                  {categoria.tituloCategoria}
                </StyledBoxListMetadataByCategoryTitle>
                <MetadataListComponent
                  metadataList={categoria.listaMetadados}
                />
                {categoria.listaMetadados.length > 1 && (
                  <StyledCategoryLink>
                    <NavLink
                      to={`/catalogo-metadados/categoria/${categoria.tituloCategoria}`}
                      style={{
                        color: 'black',
                        textDecoration: 'none',
                      }}
                    >
                      VER MAIS
                      <ChevronRightIcon fontSize="small" />
                    </NavLink>
                  </StyledCategoryLink>
                )}
              </StyledBoxListMetadataByCategory>
            );
          }
        )}
    </>
  );
};

export default ListMetadataByCategoryComponent;

import { useState } from 'react';
import { CheckboxTreeView } from '../CheckboxTreeView';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLayers } from '../../context/LayersContext';
import { geoLayers } from './layers';
import { ModalTitle, ModalContent } from './styles';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

export default function GeoLayersContent() {
  const { cleanCheckedLayers } = useLayers();
  const [showSearch, setShowSearch] = useState(false);

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const handleCloseSearch = () => {
    setShowSearch(false);
  };

  return (
    <div>
      <ModalContent>
        {showSearch && (
          <TextField
            fullWidth
            placeholder="Digite o título da camada..."
            style={{ marginBottom: '10px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCloseSearch}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
              style: { padding: '5px 5px !important' },
            }}
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'gray',
                },
              },
            }}
          />
        )}
        <ModalTitle>Camadas (GeoServer)</ModalTitle>
        <CheckboxTreeView data={geoLayers} />
        <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={cleanCheckedLayers}
            style={{
              color: 'red',
              borderColor: 'red',
              width: '170px',
              gap: '5px',
            }}
          >
            <DeleteOutlineOutlinedIcon fontSize="small" />
            Limpar Camadas
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={toggleSearch}
            style={{
              color: 'grey',
              borderColor: 'grey',
              width: '170px',
              gap: '5px',
            }}
          >
            <SearchOutlinedIcon fontSize="small" />
            Buscar Camada
          </Button>
        </Box>
      </ModalContent>
    </div>
  );
}

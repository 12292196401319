import React, { createContext, ReactNode, useEffect, useState } from 'react';
import {
  getAuthorizationToken,
  setAuthorizationToken,
  unsetAuthorizationToken,
} from '../global/storage/auth';

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextProps {
  signed: boolean;
  token: string | null;
  login: (token: string | null) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const login = (newToken: string | null) => {
    if (newToken) {
      setToken(newToken);
      setAuthorizationToken(newToken);
    }
  };

  const logout = () => {
    setToken(null);
    unsetAuthorizationToken();
  };

  useEffect(() => {
    const token = getAuthorizationToken();
    if (token) {
      setToken(token);
    }
  }, [token]);

  return (
    <AuthContext.Provider
      value={{ signed: Boolean(token), token, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

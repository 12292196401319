import { rest } from 'msw';

const loginUser = {
  email: 'teste@teste.dev',
  password: 'Teste123*',
};

const loginResponse = {
  token: 'LKub6Q65cVjbRVkbp5x9BSw3L03hdVe7wQWxQexgCX80PudWBg7KVC4vpsMi2fXy',
};

export const loginHandlers = [
  rest.post('/api/login', (req, res, ctx) => {
    const { email, password } = req.body;
    if (email === loginUser.email && password === loginUser.password) {
      return res(ctx.status(200), ctx.json(loginResponse));
    } else {
      return res(ctx.status(401), ctx.json({ error: 'Credenciais inválidas' }));
    }
  }),
];

import React, { Dispatch, SetStateAction } from 'react';
import { Tabs, Tab, Box, IconButton, Button } from '@mui/material';
import LayersIcon from '@mui/icons-material/LayersOutlined';
import ListIcon from '@mui/icons-material/ListOutlined';
import { Overlay, ModalContainer } from './styles';
import { Legend } from '../LegendComponent';
import { Dashboard } from '../DashboardComponent';

interface LegendTabComponentProps {
  selectedLegendTab: number;
  setSelectedLegendTab: Dispatch<SetStateAction<number>>;
  onClose: () => void;
  latitude: string | null;
  longitude: string | null;
  address: string | null;
  globalIrradiation: string | null;
  difuseIrradiation: string | null;
}

export default function LegendTabComponent({
  selectedLegendTab,
  setSelectedLegendTab,
  onClose,
  latitude,
  longitude,
  address,
  globalIrradiation,
  difuseIrradiation,
}: LegendTabComponentProps) {
  const handleLegendTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setSelectedLegendTab(newValue);
  };

  const renderLegendTabContent = () => {
    switch (selectedLegendTab) {
      case 0:
        return <Legend />;
      case 1:
        return (
          <Dashboard
            latitude={latitude}
            longitude={longitude}
            address={address}
            globalIrradiation={globalIrradiation}
            difuseIrradiation={difuseIrradiation}
          />
        );
    }
  };

  return (
    <Overlay onClick={onClose}>
      <ModalContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '500px',
          backgroundColor: '#fff',
          right: '20px',
          marginTop: '70px',
          height: '580px',
          overflowY: 'auto',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Tabs
          value={selectedLegendTab}
          onChange={handleLegendTabChange}
          aria-label="Drawer Tabs"
          variant="fullWidth"
          sx={{
            marginTop: '15px',
            backgroundColor: '#fff',
            borderBottom: 'none',
            '.Mui-selected': {
              color: '#9FC131 !important',
            },
            '.MuiTabs-indicator': {
              backgroundColor: '#9FC131 !important',
            },
          }}
        >
          <Tab
            icon={<ListIcon fontSize="medium" />}
            label="Legendas"
            iconPosition="start"
            sx={{
              fontWeight: '600',
              fontSize: '10px',
              minHeight: '0px !important',
            }}
          />
          <Tab
            icon={<LayersIcon />}
            label="Dashboard"
            iconPosition="start"
            sx={{
              fontWeight: '600',
              fontSize: '10px',
              minHeight: '0px !important',
            }}
          />
        </Tabs>
        <Box
          p={2}
          sx={{
            width: '100%',
            overflowY: 'auto',
            flexGrow: 1,
          }}
        >
          {renderLegendTabContent()}
        </Box>
      </ModalContainer>
    </Overlay>
  );
}

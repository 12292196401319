import { Box, Paper, Typography } from '@mui/material';
import styled from 'styled-components';

export const MetadataCatalogContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MetadataCatalogMenu = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 4rem;
  }
`;

export const PageTitle = styled(Typography)`
  && {
    font-family: Work Sans;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3rem;
    text-align: left;
  }
`;

export const StyledSearchPaper = styled(Paper)`
  && {
    padding: 2px 4px;
    display: flex;
    align-items: center;
    width: 100%;

    .MuiInputBase-root {
      margin-left: 1px;
      flex: 1;
      font-family: Work Sans;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: #707070;
      border: 0.125rem solid #ffffff;
      outline: 0.125rem solid #ffffff;
    }

    .MuiIconButton-root {
      padding: 10px;
    }
  }
`;

import styled, { css } from 'styled-components';
import { Box } from '@mui/material';

export const ModalContainer = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  width: 400px;
  height: 500px;
  background-color: white;
  z-index: 1003;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: hidden;
  transition: transform 0.3s ease-in-out;
  border-radius: 4px;
  margin-left: 10px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1002;
  margin-left: 10px;
`;

export const ModalTitle = styled.p`
  display: flex;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.typography.size.title};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  padding-bottom: 10px;
`;

export const CleanLayersButton = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.red};
    background: none;
    border: none;
    font-family: ${theme.typography.fontFamily};
    font-size: 1.6rem;
    font-weight: 500;
    padding: 0.5rem;
    position: absolute;
    top: 1rem;
    right: 3rem;
    border-radius: 3px;

    &:hover {
      background-color: #0000000a;
    }
  `}
`;

export const IconContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  margin-top: 370px;
`;

export const IconWrapper = styled(Box)`
  display: flex;
  color: #707070;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #f5f5f5;
  &:hover,
  &:active,
  &.active {
    cursor: pointer;
    color: #f5f5f5;
    background-color: ${({ theme }) => theme.colors.greenDark};
  }
`;

import { useEventHandlers } from '@react-leaflet/core';
import { Map } from 'leaflet';
import { useCallback, useMemo, useState } from 'react';
import { MapContainer, Rectangle, useMap, useMapEvent } from 'react-leaflet';
import MapTileLayer from '../MapTileLayer';

type IMiniMapBoundsProps = {
  parentMap: Map;
  zoom: number;
};
function MiniMapBounds({ parentMap, zoom }: IMiniMapBoundsProps) {
  const miniMap = useMap();

  const OnClick = useCallback(
    (e: any) => {
      parentMap.setView(e.latlng, parentMap.getZoom());
    },
    [parentMap]
  );

  useMapEvent('click', OnClick);

  const [bounds, setBounds] = useState(parentMap.getBounds());
  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds());

    miniMap.setView(parentMap.getCenter(), zoom);
  }, [miniMap, parentMap, zoom]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), []);

  useEventHandlers(
    {
      instance: parentMap,
      context: {
        __version: 0,
        map: parentMap,
      },
    },
    handlers
  );

  return (
    <Rectangle bounds={bounds} pathOptions={{ color: '#000', weight: 1 }} />
  );
}
type IMiniMapProps = {
  zoom?: Partial<number>;
};
export function MiniMap({ zoom }: IMiniMapProps) {
  const parentMap = useMap();
  const mapZoom = zoom || 0;

  const minimap = useMemo(
    () => (
      <MapContainer
        style={{
          width: '289px',
          height: '232px',
          borderRadius: '6px',
          border: '5px solid white',
        }}
        center={parentMap.getCenter()}
        zoom={mapZoom}
        dragging={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomControl={false}
      >
        <MapTileLayer />
        <MiniMapBounds parentMap={parentMap} zoom={mapZoom} />
      </MapContainer>
    ),
    [mapZoom, parentMap]
  );

  return <div>{minimap}</div>;
}

import { FormContainer, FormTextField, FormTitle } from '../../Components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ProfileType, UserInviteType } from '../../global/types/user';
import { MenuItem } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ApiGetProfiles, ApiSendInvite } from '../../services/api';
import { AlertContext } from '../../context/AlertContext';

const inviteUserSchema = yup.object({
  email: yup
    .string()
    .email('Entre com um email válido')
    .required('O email é nescessário'),
  name: yup.string().required('Digite o nome do convidado'),
  profileId: yup.number().required('Selecione o perfil do convidado'),
});

export const InviteUser: React.FC = () => {
  const [profiles, setProfiles] = useState<ProfileType[]>([]);

  useEffect(() => {
    const getProfiles = async () => {
      try {
        const { content } = await ApiGetProfiles();
        setProfiles(content);
      } catch (error) {
        //   setAlertMessage('error', (error as Error).message);
      } finally {
      }
    };
    getProfiles();
  }, []);
  const { setAlertMessage } = useContext(AlertContext);

  const handleLogin = async (values: UserInviteType) => {
    try {
      const { message } = await ApiSendInvite(values);
      await setAlertMessage('success', message);
    } catch (error) {
      setAlertMessage('error', (error as Error).message);
    } finally {
    }
  };

  const userInviteForm = useFormik({
    initialValues: {
      email: '',
      name: '',
      profileId: 0,
    },
    validationSchema: inviteUserSchema,
    onSubmit: handleLogin,
  });

  return (
    <FormContainer
      onSubmit={userInviteForm.handleSubmit}
      submitLabel="Cadastrar"
    >
      <FormTitle>Convite de Cadastro</FormTitle>
      <FormTextField
        standartLabel="Nome"
        placeholder="Digite o nome do convidado."
        type="text"
        name="name"
        value={userInviteForm.values.name}
        onChange={userInviteForm.handleChange}
        onBlur={userInviteForm.handleBlur}
        error={
          userInviteForm.touched.name && Boolean(userInviteForm.errors.name)
        }
        helperText={userInviteForm.touched.name && userInviteForm.errors.name}
        required
      />
      <FormTextField
        standartLabel="E-mail"
        placeholder="abcdef@exemplo.com"
        type="email"
        name="email"
        value={userInviteForm.values.email}
        onChange={userInviteForm.handleChange}
        onBlur={userInviteForm.handleBlur}
        error={
          userInviteForm.touched.email && Boolean(userInviteForm.errors.email)
        }
        helperText={userInviteForm.touched.email && userInviteForm.errors.email}
        required
      />
      <FormTextField
        standartLabel="Perfil"
        placeholder="Selecione o perfil do convidado"
        select
        type="select"
        name="profileId"
        value={userInviteForm.values.profileId}
        onChange={userInviteForm.handleChange}
        onBlur={userInviteForm.handleBlur}
        error={
          userInviteForm.touched.profileId &&
          Boolean(userInviteForm.errors.profileId)
        }
        helperText={
          userInviteForm.touched.profileId && userInviteForm.errors.profileId
        }
        required
      >
        {profiles.map((profile) => (
          <MenuItem key={profile.id} value={profile.id}>
            {profile.name}
          </MenuItem>
        ))}
      </FormTextField>
    </FormContainer>
  );
};

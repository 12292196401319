import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { HomeContainer } from '../../styles';
import { StyledMain } from '../styles';
import { MetadataCatalogMenu, PageTitle, StyledSearchPaper } from './styles';
import { IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Footer } from '../../../Components';
import {
  MetadataItemComponent,
  MetadataPreviewProps,
} from '../components/MetadataItemComponent';
import { Loading } from '../components/Loading';
import { getFormattedAuthorizationToken } from '../../../global/storage/auth';
import {
  StyledBoxListMetadataByCategoryTitle,
  StyledListContainer,
} from '../components/styles';
import ListMetadataByCategoryComponent, {
  CategoriesWithMetadataType,
} from '../components/ListMetadataByCategoryComponent';

// const groupByCategory = (metadata: MetadataPreviewProps[]) => {
//   const teste = metadata.reduce((acc: { [key: string]: MetadataPreviewProps[] }, item) => {
//     if (!acc[item.categoriaTematica]) {
//       acc[item.categoriaTematica] = [];
//     }
//     acc[item.categoriaTematica].push(item);
//     return acc;
//   }, {});

//   return teste;
// };

const groupByCategory = (
  metadata: MetadataPreviewProps[]
): CategoriesWithMetadataType[] => {
  const result = metadata.reduce((acc: CategoriesWithMetadataType[], item) => {
    const existingCategory = acc.find(
      (cat) => cat.tituloCategoria === item.categoriaTematica
    );

    if (existingCategory) {
      if (existingCategory.listaMetadados.length < 2) {
        existingCategory.listaMetadados.push(item);
      }
    } else {
      acc.push({
        tituloCategoria: item.categoriaTematica,
        listaMetadados: [item],
      });
    }

    return acc;
  }, [] as CategoriesWithMetadataType[]);

  return result;
};

export function MetadataCatalog() {
  const [term, setTerm] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [metadataList, setMetadataList] = useState<MetadataPreviewProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleTerm = (event: ChangeEvent<HTMLInputElement>) => {
    setTerm(event.target.value);
  };

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSearchTerm(term); // Trigger search based on the term entered
  };

  const fetchMetadata = async () => {
    try {
      const token = getFormattedAuthorizationToken();

      const response = await fetch(
        'https://api.dev.atlas.isitics.com/metadata',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }
      );

      if (!response.ok) {
        const errorResponse = await response.text();
        console.error('Erro na resposta da API:', errorResponse);
        throw new Error(`Erro na resposta da API: ${response.statusText}`);
      }

      const data = await response.json();
      const mappedData = data.content.map((item: any) => ({
        id: item.id.toString(),
        titulo: item.title,
        dataDivulgacao: new Date(item.release_date).toLocaleDateString(),
        resumo: item.resume,
        responsavel: item.responsible,
        statusDesenvolvimento: item.status,
        imagem: item.url_image,
        categoriaTematica: item.thematic_category,
      }));

      setMetadataList(mappedData);
    } catch (error) {
      console.error('Erro ao carregar metadados:', error);
      setError('Erro ao carregar metadados');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMetadata();
  }, []);

  const filteredMetadataList = metadataList.filter((item) => {
    const searchTermLower = searchTerm.toLowerCase();
    return item.titulo.toLowerCase().includes(searchTermLower);
  });

  const groupedMetadata = groupByCategory(filteredMetadataList);

  if (loading) return <Loading />;
  if (error) return <div>{error}</div>;

  return (
    <HomeContainer>
      <StyledMain>
        <MetadataCatalogMenu>
          <PageTitle>Catálogo de metadados</PageTitle>
          <form onSubmit={handleSearch}>
            <StyledSearchPaper>
              <InputBase
                placeholder="Buscar por Metadado..."
                value={term}
                onChange={handleTerm}
              />
              <IconButton type="submit">
                <SearchIcon />
              </IconButton>
            </StyledSearchPaper>
          </form>
        </MetadataCatalogMenu>
        {groupedMetadata && (
          <ListMetadataByCategoryComponent
            categories={groupedMetadata as CategoriesWithMetadataType[]}
          />
        )}
      </StyledMain>
      <Footer />
    </HomeContainer>
  );
}

import React from 'react';
import { CircularProgress } from '@mui/material';
import { StyledLoadingProgress } from './styles';

// import { Container } from './styles';

export const Loading: React.FC = () => {
  return (
    <StyledLoadingProgress>
      <CircularProgress />
    </StyledLoadingProgress>
  );
};

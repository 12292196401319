import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HomeContainer } from '../../styles';
import { StyledMetadataMenu, StyledBackLink, StyledMain } from '../styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { StyledCategoryLabel } from './styles';
import { useAxios } from '../../../hooks/useAxios';
import { Footer } from '../../../Components';
import MetadataListComponent from '../components/MetadataListComponent';
import { MetadataPreviewProps } from '../components/MetadataItemComponent';
import { Loading } from '../components/Loading';
import { API_CATEGORY_METADATA_ROUTE } from '../../../global/constants/routes/apiConstants';
import { METADATA_ROUTE } from '../../../global/constants/routes/metadataConstants';
import { getFormattedAuthorizationToken } from '../../../global/storage/auth';

export const MetadataCatalogByCategory: React.FC = () => {
  const { category } = useParams<{ category: string }>();
  const [metadataList, setMetadataList] = useState<MetadataPreviewProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  const handleBackClick = () => {
    window.location.href = METADATA_ROUTE;
  };

  const fetchMetadata = async () => {
    try {
      const token = getFormattedAuthorizationToken();

      const response = await fetch(`https://api.dev.atlas.isitics.com/metadata/category/${category}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (!response.ok) {
        const errorResponse = await response.text();
        console.error('Erro na resposta da API:', errorResponse);
        throw new Error(`Erro na resposta da API: ${response.statusText}`);
      }

      const data = await response.json();
      const mappedData = data.content.map((item: any) => ({
        id: item.id.toString(),
        titulo: item.title,
        dataDivulgacao: new Date(item.release_date).toLocaleDateString(),
        resumo: item.resume,
        responsavel: item.responsible,
        statusDesenvolvimento: item.status,
        imagem: item.url_image,
        categoriaTematica: item.thematic_category,
      }));

      setMetadataList(mappedData);
    } catch (error) {
      console.error('Erro ao carregar metadados:', error);
      setError('Erro ao carregar metadados');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMetadata();
  }, []);

  if (loading) return <Loading />;
  if (error) return <div>{error}</div>;

  return (
    <HomeContainer>
      <StyledMain>
        <StyledMetadataMenu>
          <StyledBackLink onClick={handleBackClick}>
            <ChevronLeftIcon fontSize="large" />
          </StyledBackLink>
          <StyledCategoryLabel>{category}</StyledCategoryLabel>
        </StyledMetadataMenu>
        {metadataList !== null && (
          <MetadataListComponent metadataList={metadataList} />
        )}
      </StyledMain>
      <Footer></Footer>
    </HomeContainer>
  );
};

import axios, { AxiosError } from 'axios';
import {
  CredentialsType,
  DefaultReponseType,
  EnableUserResponseType,
  LoginResponseType,
  ProfileListResponseType,
  SendPasswordRecoveryEmailType,
  UserInviteType,
  UserType,
} from '../global/types/user';
import {
  API_OAUTH_ROUTE,
  API_PROFILE_ROUTE,
  API_USER_CHANGE_PASSWORD_ROUTE,
  API_USER_ENABLE_ROUTE,
  API_USER_INVITATE_ROUTE,
  API_USER_ROUTE,
  API_USER_SEND_EMAIL_CHANGE_PASSWORD_ROUTE,
} from '../global/constants/routes/apiConstants';
import { getFormattedAuthorizationToken } from '../global/storage/auth';

export const BASE_URL_API =
  process.env.URL_API || 'https://api.dev.atlas.isitics.com';

export const apiService = axios.create({ baseURL: BASE_URL_API });

export const handleError = (error: any) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      const errorData = axiosError.response.data;
      return errorData;
    }
  }
  return error;
};

export const ApiRegisterUser = async (user: UserType) => {
  try {
    const response = await apiService.post(API_USER_ROUTE, user);
    const data = response.data;
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const ApiRegisterUserWithToken = async (
  inviteToken: string,
  user: UserType
) => {
  try {
    const response = await apiService.post(API_USER_ROUTE, user, {
      headers: {
        Authorization: `token ${inviteToken}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const ApiGetUser = async () => {
  try {
    const response = await apiService.get(API_USER_ROUTE);
    const data = response.data;
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const ApiSendPasswordRecoveryEmail = async (
  values: SendPasswordRecoveryEmailType
) => {
  try {
    const response = await apiService.post(
      API_USER_SEND_EMAIL_CHANGE_PASSWORD_ROUTE,
      values
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const ApiRecoveryPassword = async (
  recoveryToken: string,
  values: { password: string }
) => {
  try {
    const response = await apiService.post(
      API_USER_CHANGE_PASSWORD_ROUTE,
      values,
      {
        headers: {
          Authorization: `token ${recoveryToken}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const ApiEnableUser = async (
  activationToken: string
): Promise<EnableUserResponseType> => {
  try {
    const response = await apiService.post(API_USER_ENABLE_ROUTE, null, {
      headers: {
        Authorization: `token ${activationToken}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const ApiLogin = async (
  credentials: CredentialsType,
  login: (token: string) => void
): Promise<LoginResponseType> => {
  try {
    const response = await apiService.post(API_OAUTH_ROUTE, credentials);
    const data: LoginResponseType = response.data;
    login(data.content);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const ApiLogout = async () => {
  try {
    apiService.defaults.headers.Authorization =
      getFormattedAuthorizationToken();
    const response = await apiService.put(API_OAUTH_ROUTE);
    const data: LoginResponseType = response.data;
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const ApiGetProfiles = async () => {
  try {
    apiService.defaults.headers.Authorization =
      getFormattedAuthorizationToken();
    const response = await apiService.get(API_PROFILE_ROUTE);
    const data: ProfileListResponseType = response.data;
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const ApiSendInvite = async (values: UserInviteType) => {
  try {
    apiService.defaults.headers.Authorization =
      getFormattedAuthorizationToken();
    const response = await apiService.post(API_USER_INVITATE_ROUTE, values);
    const data: DefaultReponseType = response.data;
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

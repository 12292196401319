import React, { useState } from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { useMap } from 'react-leaflet';

export default function ZoomControl() {
  const map = useMap();
  const [isHoverIn, setIsHoverIn] = useState(false);
  const [isHoverOut, setIsHoverOut] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActiveOut, setIsActiveOut] = useState(false);

  function handleZoomIn() {
    setIsActive(true);
    map.setZoom(map.getZoom() + 1);
    setTimeout(() => setIsActive(false), 200);
  }

  function handleZoomOut() {
    setIsActiveOut(true);
    map.setZoom(map.getZoom() - 1);
    setTimeout(() => setIsActiveOut(false), 200);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        gap: 'auto',
        marginLeft: '20px',
      }}
    >
      <button
        style={{
          backgroundColor: isHoverIn || isActive ? '#015C53' : '#f5f5f5',
          color: isHoverIn || isActive ? '#fff' : '#707070',
          border: 'none',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          width: '40px',
          height: '40px',
          cursor: 'pointer',
        }}
        onMouseDown={() => handleZoomIn()}
        onMouseUp={() => setIsActive(false)}
        onMouseEnter={() => setIsHoverIn(true)}
        onMouseLeave={() => setIsHoverIn(false)}
      >
        <ZoomInIcon sx={{ fontSize: '2.3rem' }} />
      </button>
      <button
        style={{
          backgroundColor: isHoverOut || isActiveOut ? '#015C53' : '#f5f5f5',
          color: isHoverOut || isActiveOut ? '#fff' : '#707070',
          border: 'none',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          width: '40px',
          height: '40px',
          cursor: 'pointer',
          marginBottom: '10px',
        }}
        onMouseDown={() => handleZoomOut()}
        onMouseUp={() => setIsActiveOut(false)}
        onMouseEnter={() => setIsHoverOut(true)}
        onMouseLeave={() => setIsHoverOut(false)}
      >
        <ZoomOutIcon sx={{ fontSize: '2.3rem' }} />
      </button>
    </div>
  );
}

import { TextField } from '@mui/material';
import { styled } from '@mui/system';

export const SearchTextField = styled(TextField, {
  name: 'SearchTextField',
})({
  width: 300,

  '& .MuiInputBase-root': {
    paddingRight: '0px',
    backgroundColor: 'white',
  },
  '& legend': { display: 'none' },
  '& fieldset': { border: 'none' },
  '& .MuiInputLabel-shrink': { opacity: 0, transition: 'all 0.2s ease-in' },
});

import React, { useContext, useState } from 'react';
import { IconButton } from '@mui/material';
import {
  StyledMenuButton,
  StyledLoginMenu,
  StyledFormContainer,
  StyledForm,
  StyledTitleContainer,
  StyledTitle,
  StyledCenterBlackLink,
  StyledRedLink,
} from './styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { FormTextField } from '../../../Components';
import { FormSubmitButton } from '../../Form/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { REGISTER_ROUTE } from '../../../global/constants/routes/userConstants';
import { AuthContext } from '../../../context/AuthContext';
import { ApiLogin } from '../../../services/api';
import { AlertContext } from '../../../context/AlertContext';
import { CredentialsType } from '../../../global/types/user';

const LoginValidationSchema = yup.object({
  email: yup
    .string()
    .email('Entre com um email válido')
    .required('O email é nescessário'),
  password: yup.string().required('A senha é necessária'),
});

export function LoginMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { login } = useContext(AuthContext);
  const { setAlertMessage } = useContext(AlertContext);

  const handleLogin = async (values: CredentialsType) => {
    try {
      const { message } = await ApiLogin(values, login);
      setAlertMessage('success', message);
    } catch (error) {
      setAlertMessage('error', (error as Error).message);
    } finally {
    }
  };

  const loginForm = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginValidationSchema,
    onSubmit: handleLogin,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFormKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (
      event.key === 'Tab' ||
      event.key === 'L' ||
      event.key === 'l' ||
      event.key === 'Shift'
    ) {
      event.stopPropagation();
    }
  };

  return (
    <>
      <StyledMenuButton onClick={handleClick}>
        <IconButton>
          <AccountCircle />
        </IconButton>
        <label>ENTRAR</label>
      </StyledMenuButton>

      <StyledLoginMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <StyledFormContainer>
          <StyledForm
            onSubmit={loginForm.handleSubmit}
            onKeyDown={handleFormKeyDown}
          >
            <StyledTitleContainer>
              <StyledTitle variant="h5" content="h1">
                Login
              </StyledTitle>
            </StyledTitleContainer>
            <>
              <FormTextField
                standartLabel="E-mail"
                type="email"
                name="email"
                placeholder="abcdef@exemple.com"
                value={loginForm.values.email}
                onChange={loginForm.handleChange}
                onBlur={loginForm.handleBlur}
                error={
                  loginForm.touched.email && Boolean(loginForm.errors.email)
                }
                helperText={loginForm.touched.email && loginForm.errors.email}
                required
              />
              <FormTextField
                standartLabel="Senha"
                type="password"
                name="password"
                placeholder="*****"
                value={loginForm.values.password}
                onChange={loginForm.handleChange}
                onBlur={loginForm.handleBlur}
                error={
                  loginForm.touched.password &&
                  Boolean(loginForm.errors.password)
                }
                helperText={
                  loginForm.touched.password && loginForm.errors.password
                }
                required
              />
              <StyledRedLink>Esqueci a senha</StyledRedLink>
              <FormSubmitButton type="submit" variant="contained">
                Entrar
              </FormSubmitButton>
            </>
{/* 
            <StyledCenterBlackLink href={REGISTER_ROUTE}>
              Cadastre-se
            </StyledCenterBlackLink> */}
          </StyledForm>
        </StyledFormContainer>
      </StyledLoginMenu>
    </>
  );
}

import * as L from 'leaflet';
import 'leaflet-measure';
import 'leaflet-measure/dist/leaflet-measure.css';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
export function MeasureComponent() {
  const map = useMap();
  //@ts-ignore
  useEffect(() => {
    // _bugfix
    //@ts-ignore
    L.Control.Measure.include({
      _setCaptureMarkerIcon: function () {
        this._captureMarker.options.autoPanOnFocus = false;

        this._captureMarker.setIcon(
          L.divIcon({
            iconSize: this._map.getSize().multiplyBy(2),
          })
        );
      },
    });
    //@ts-ignore
    const measureControl = new L.Control.Measure({
      position: 'bottomright',
      primaryLengthUnit: 'kilometers',
      secondaryLengthUnit: null,
      primaryAreaUnit: 'sqmeters',
      secondaryAreaUnit: null,
      activeColor: 'rgb(159, 193, 49)',
      completedColor: 'rgb(159, 193, 49)',
    });

    measureControl.addTo(map);
    return () => map.removeControl(measureControl);
  }, []);
  return null;
}

import { Box } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as Logo } from './logo-with-description.svg';

export const FormPageLayoutContainer = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    margin-bottom: 10rem;
    gap: 2.5rem;
    align-items: center;
  }
`;

export const StyledLogo = styled(Logo)`
  && {
    width: 20.12rem;
    height: 8rem;
  }
`;

import { createContext, useState, useContext, ReactNode } from 'react';

interface IUploadedFiles {
  addNewFile(layer: any): void;
  files: any[];
  cleanFiles(): void;
}

interface IUploadedFilesProvider {
  children: ReactNode;
}

const FilesContext = createContext<IUploadedFiles>({} as IUploadedFiles);

export const UploadedFilesProvider = ({ children }: IUploadedFilesProvider) => {
  const [files, setFiles] = useState([]);

  //@ts-ignore
  function addNewFile(layer: any) {
    //@ts-ignore
    setFiles((prev) => [...prev, layer]);
  }

  function cleanFiles() {
    setFiles([]);
  }

  return (
    <FilesContext.Provider value={{ addNewFile, files, cleanFiles }}>
      {children}
    </FilesContext.Provider>
  );
};

export function useFiles() {
  const context = useContext(FilesContext);

  if (!context) {
    throw new Error('usePageContext must be used within a PageProvider');
  }

  return context;
}

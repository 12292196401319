import styled from 'styled-components';
import { MapContainer } from 'react-leaflet';
import rulerImage from '../../assets/ruler.png';

export const MapBox = styled(MapContainer)`
  width: 100%;
  height: calc(100vh - 128px);
  margin-top: 64px;
  font-size: 12px !important;

  & .leaflet-top {
    margin-top: 84px;

    .leaflet-bottom .leaflet-control {
      margin-bottom: 20px;
    }

    .result-tooltip {
      font-size: 10px !important;
    }

    .moving-tooltip {
      font-size: 10px;
    }

    .leaflet-ruler {
      height: 30px;
      width: 30px;
      background-image: url(${rulerImage});
      background-repeat: no-repeat;
      background-size: 15px 15px;
    }

    .leaflet-ruler:hover {
      background-image: url(${rulerImage});
      background-size: 15px 15px;
    }

    .leaflet-ruler-clicked {
      height: 30px;
      width: 30px;
      background-repeat: no-repeat;
      background-position: center;
      border-color: #c6c6c6 !important;
    }
  }
`;

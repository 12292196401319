import 'leaflet-draw/dist/leaflet.draw.css';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { GeoJSON, ScaleControl } from 'react-leaflet';
// import RulerControl from 'react-leaflet-ruler';
import ReactLeafletKml from 'react-leaflet-kml';
import MoveButtons from '../Buttons/MoveButtons';
import { Legend } from '../LegendComponent';
import MapTileLayer from '../MapTileLayer';
import { MiniMap } from '../MiniMap';
import ZoomControl from '../ZoomControl';
// import { RenderAdditionalInformationLayers } from './RenderAdditionalInformationLayers';
// import { RenderPotencialEolico } from './RenderPotencialEolico';
// import { RenderPotencialSolar } from './RenderPotencialSolar';
// import { RenderSistemaEletricoExistente } from './RenderSistemaEletricoExistente';
// import { RenderSistemaEletricoPlanejado } from './RenderSistemaEletricoPlanejado';
// import { RenderSocioambiental } from './RenderSocioambiental';
import { useFiles } from '../../context/UploadedFilesContext';
import { RenderAttributeTable } from '../RenderAttributeTable';
import { RenderSenarios } from './RenderSenarios';
import { MapBox } from './styles';

type IMapComponentProps = {
  children: ReactNode;
  showMiniMap: boolean;
  leftPosition: string;
  setLatitude: Dispatch<SetStateAction<string | null>>;
  setLongitude: Dispatch<SetStateAction<string | null>>;
  setAddress: Dispatch<SetStateAction<string | null>>;
  setGlobalIrradiation: Dispatch<SetStateAction<string | null>>;
  setDifuseIrradiation: Dispatch<SetStateAction<string | null>>;
};

export function MapComponent({
  setLatitude,
  setLongitude,
  setAddress,
  setGlobalIrradiation,
  setDifuseIrradiation,
  children,
  showMiniMap,
  leftPosition,
}: IMapComponentProps) {
  const { files, addNewFile } = useFiles();

  return (
    <MapBox
      zoom={10}
      scrollWheelZoom={true}
      zoomControl={false}
      center={[-8.05, -34.900002]}
    >
      {/* <Legend /> */}
      {showMiniMap && (
        <div
          style={{
            position: 'absolute',
            top: '410px',
            zIndex: 1000,
            left: leftPosition,
            transition: 'left 0.3s ease',
          }}
        >
          <MiniMap zoom={4} />
        </div>
      )}
      {/* <MoveButtons /> */}
      <MapTileLayer />
      {files &&
        files?.map((elem) => {
          if (elem.type.includes('kml')) {
            return <ReactLeafletKml kml={elem.layer} />;
          }
          if (elem.type.includes('json')) {
            return <GeoJSON data={elem.layer} />;
          }
          if (elem.type.includes('shape')) {
            return <GeoJSON data={elem.layer} />;
          }
        })}
      {/* {kml && <ReactLeafletKml kml={kml} />}
      {geoJsonData && <GeoJSON data={geoJsonData} />}
      <RenderCityLayer />
      <RenderGeoServerLayers />
      {/* <RenderAdditionalInformationLayers /> */}
      {/* <RenderPotencialEolico /> */}
      {/* <RenderPotencialSolar /> */}
      {/* <RenderSistemaEletricoExistente /> */}
      {/* <RenderSistemaEletricoPlanejado /> */}
      {/* <RenderSocioambiental /> */}
      <RenderAttributeTable
        setLatitude={setLatitude}
        setLongitude={setLongitude}
        setAddress={setAddress}
        setGlobalIrradiation={setGlobalIrradiation}
        setDifuseIrradiation={setDifuseIrradiation}
      />
      {/* <RenderCityLayer /> */}
      <ScaleControl />
      <RenderSenarios />
      {children}
    </MapBox>
  );
}

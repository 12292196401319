import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  top: 60px;
  right: 20px;
  width: 500px;
  height: 500px;
  background-color: white;
  z-index: 1004;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
  border-radius: 6px;
  margin-left: 24px;
  overflow-y: auto;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 20px;
  width: 100vw;
  z-index: 1002;
  margin-right: 10px;
`;

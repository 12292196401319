import { Box, Link, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledLoadingProgress = styled(Box)`
  && {
    display: flex;
    justify-content: center;
    align-content: center;
  }
`;

export const StyledBoxListMetadataByCategory = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 30px;
  }
`;

export const StyledBoxListMetadataByCategoryTitle = styled(Typography)`
  && {
    font-family: Work Sans;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.34625rem;
    text-align: left;
    color: #000000;
  }
`;

export const StyledCategoryLink = styled(Link)`
  color: black;
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  && {
    font-family: Work Sans;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5625rem;
    text-align: right;
    align-items: center;
    color: #000000;
    text-decoration: underline;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.0625rem;
      background-color: #000000;
    }

    &:hover {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0.0625rem;
        background-color: #000000;
      }
    }
  }

  // Estilizando o ícone
  & > svg {
    margin-left: 0.25rem; // Ajusta a distância entre o texto e o ícone
    vertical-align: middle; // Alinha verticalmente o ícone com o texto
  }
`;

export const StyledListContainer = styled(Box)`
  && {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem 0rem 2rem;
    gap: 1rem;
  }
`;

export const StyledItemContainer = styled(Box)`
  && {
    background-color: #ffffff;
    background-color: #ffffff;
    border-radius: 0.5rem;
    height: 22rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;

    &:hover {
      transform: scale(1.01);
    }
  }
`;

export const StyledItemImg = styled.img`
  && {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    grid-column: span 5;
    max-width: calc(100% - 5rem);
    max-height: calc(100% - 5rem);
    align-self: center;
    justify-self: center;
  }
`;

export const StyledItemTextual = styled(Box)`
  && {
    grid-column: span 7;
    padding: 1.5rem 1rem;
    height: auto;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const StyledItemTextualTitle = styled(Typography)`
  && {
    font-family: Work Sans;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.75rem;
    text-align: left;
    color: #015c53;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const StyledItemTextualSummary = styled(Typography)`
  && {
    font-family: Work Sans;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.2rem;
    text-align: justify;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #000000;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const StyledItemTextualText = styled(Typography)`
  && {
    font-family: Work Sans;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.2rem;
    text-align: justify;
    color: #000000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const StyledItemTextualKeyValue = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const StyledItemTextualTextKey = styled(Typography)`
  && {
    font-family: Work Sans;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2rem;
    text-align: left;
    color: #000000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const StyledItemTextualTextValue = styled(Typography)`
  && {
    font-family: Work Sans;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.2rem;
    text-align: right;
    color: #00000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const StyledItemTextualTextStatus = styled(Typography)`
  && {
    font-family: Work Sans;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.2rem;
    text-align: right;
    width: fit-content;
    margin-left: auto;
  }
`;

export const TextualBold = styled(Typography)`
  && {
    font-family: Work Sans;
    font-weight: 600;
    font-size: 1.2rem;
  }
`;

import { rest } from 'msw';
import metadataJson from './metadata.json';
import { API_METADATA_ROUTE } from '../../global/constants/routes/apiConstants';

export const metadataHandlers = [
  rest.get(API_METADATA_ROUTE, (req, res, ctx) => {
    const searchTerm = req.url.searchParams.get('search');

    // Se o termo de pesquisa existir, filtre os metadados com base nele
    if (searchTerm) {
      const filteredMetadata = metadataJson.filter((metadata) =>
        metadata.titulo.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return res(ctx.json(filteredMetadata));
    }

    // Caso contrário, agrupe os metadados por categoria e retorne os dois primeiros de cada categoria
    const metadataByCategory = metadataJson.reduce((acc, metadata) => {
      const { categoriaTematica } = metadata;
      if (!acc[categoriaTematica]) {
        acc[categoriaTematica] = [];
      }
      acc[categoriaTematica].push(metadata);
      return acc;
    }, {});

    const groupedMetadata = [];
    for (const category in metadataByCategory) {
      groupedMetadata.push({
        tituloCategoria: category,
        listaMetadados: metadataByCategory[category]
          .slice(0, 2)
          .map((metadata) => ({
            titulo: metadata.titulo,
            ...metadata,
          })),
      });
    }

    return res(ctx.json(groupedMetadata));
  }),

  rest.get(`${API_METADATA_ROUTE}/category/:category`, (req, res, ctx) => {
    const { category } = req.params;
    const metadataList = metadataJson.filter(
      (item) => item.categoriaTematica === category
    );
    if (!metadataList) {
      return res(ctx.status(404));
    }
    return res(ctx.json(metadataList));
  }),

  rest.get(`${API_METADATA_ROUTE}/:pk`, (req, res, ctx) => {
    const { pk } = req.params;
    const metadataList = metadataJson.find((item) => item.id === pk);
    if (!metadataList) {
      return res(ctx.status(404));
    }
    return res(ctx.json(metadataList));
  }),
];

import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AppBar, Toolbar } from '@mui/material';
import { HeaderContainer, NavContainter } from './styles';
import logo from './assets/logo.png';
import { LoginMenu } from './components/LoginMenu';
import { ProfileMenu } from './components/ProfileMenu';
import { getAuthorizationToken } from '../../global/storage/auth';
import { AuthContext } from '../../context/AuthContext';

export function Navbar() {
  const { signed } = useContext(AuthContext);
  const [, setAuth] = useState(false);

  useEffect(() => {
    if (getAuthorizationToken()) {
      setAuth(true);
    }
  }, []);

  return (
    <AppBar>
      <HeaderContainer>
        <Toolbar>
          <NavLink to="/">
            <img src={logo} alt="logo" />
          </NavLink>
        </Toolbar>
        <NavContainter>
          <NavLink to="/">
            <label>Geoportal</label>
          </NavLink>
          {/* <NavLink to="/relatorios">
            <label>Relatórios</label>
          </NavLink> */}
          <NavLink to="/catalogo-metadados">
            <label>Catálogo de metadados</label>
          </NavLink>
          {/* <NavLink to="/sobre">
            <label>Sobre</label>
          </NavLink> */}
        </NavContainter>
        {signed ? <ProfileMenu /> : <LoginMenu />}
      </HeaderContainer>
    </AppBar>
  );
}

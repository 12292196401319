import styled from 'styled-components';
import { Button } from '@mui/material';

export const ModalContainer = styled.div`
  position: absolute;
  top: 230px;
  left: 130px; 
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  gap: 32px;
  box-shadow: 24px;
  padding: 20px;
  z-index: 1001;
  border-radius: 4px;
  z-index: 1000;
`;

export const ModalPaper = styled.div`
  position: absolute;
  top: 230px;
  left: 130px;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 24px;
  padding: 20px;
  z-index: 1001;
  border-radius: 4px;
  text-align: left;
  width: 320px;
`;

export const ModalHeader = styled.h1`
  font-size: ${({ theme }) => theme.typography.size.header};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  margin-bottom: 10px;
`;

export const ModalDescription = styled.p`
  font-size: 12px;
  color: black;
  margin-bottom: 20px;
  font-weighy: 700;
`;

export const UploadButton = styled(Button)`
  width: 100%;
  height: 50px;
  background-color: #9FC131;
  color: #ffffff;
  font-size: 12px;
`;

export const RemoveButton = styled(Button)`
  width: 100%;
  height: 50px;
`;

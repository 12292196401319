import React from 'react';
import { useFiles } from '../../context/UploadedFilesContext';
import * as shapefile from 'shapefile';
import {
  ModalContainer,
  ModalPaper,
  ModalHeader,
  ModalDescription,
  RemoveButton,
} from './styles';
import Button, { ButtonProps } from '@material-ui/core/Button';

interface UploadButtonProps extends ButtonProps {
  component: string;
}

const UploadButton: React.FC<UploadButtonProps> = (props) => {
  return <Button {...props} />;
};

interface UploadFileModalType {
  show: boolean;
  onClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

interface UploadFileModalType {
  show: boolean;
  onClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

export function UploadFileModal({ show }: UploadFileModalType) {
  const { addNewFile, cleanFiles } = useFiles();

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.type.includes('kml')) {
        handleUploadKML(file);
      }
      if (file.type.includes('json')) {
        handleGeoJsonFileChange(file);
      }
      if (file.type.includes('shape')) {
        handleShapefileFileChange(file);
      }
    }
  };

  const handleUploadKML = (file: any) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const kmlContent = event.target.result as string;
          const parser = new DOMParser();
          const kmlDocument = parser.parseFromString(kmlContent, 'text/xml');
          addNewFile({
            layer: kmlDocument,
            type: file.type,
          });
        }
      };
      reader.readAsText(file);
    }
  };

  const handleGeoJsonFileChange = (file: any) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const geoJsonContent = event.target.result as string;
          addNewFile({
            layer: JSON.parse(geoJsonContent),
            type: file.type,
          });
        }
      };
      reader.readAsText(file);
    }
  };

  const handleShapefileFileChange = (file: any) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        if (event.target) {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const data = await shapefile.read(arrayBuffer);
          addNewFile({
            layer: data,
            type: file.type,
          });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return show ? (
    <ModalContainer
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <ModalPaper>
        <ModalHeader>Importar um Arquivo</ModalHeader>
        <ModalDescription>
          Selecione um arquivo do tipo Shapefile ou KML correspondente à
          plataforma.
        </ModalDescription>
        <UploadButton
          variant="contained"
          component="label"
          style={{
            backgroundColor: '#9FC131',
            color: 'white',
            width: '100%',
            height: '50px',
          }}
        >
          Fazer Upload
          <input
            id="fileInput"
            type="file"
            style={{ display: 'none' }}
            onChange={handleUpload}
            accept=".shp,.kml,.json"
          />
        </UploadButton>
        <RemoveButton onClick={() => cleanFiles()}>
          Remover camadas
        </RemoveButton>
      </ModalPaper>
    </ModalContainer>
  ) : null;
}

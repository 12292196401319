export const API_USER_ROUTE = '/user';
export const API_USER_ENABLE_ROUTE = `${API_USER_ROUTE}/enable`;
export const API_USER_SEND_EMAIL_CHANGE_PASSWORD_ROUTE = `${API_USER_ROUTE}/sendEmailChangePassword`;
export const API_USER_CHANGE_PASSWORD_ROUTE = `${API_USER_ROUTE}/changePassword`;

export const API_OAUTH_ROUTE = '/oauth';

export const API_METADATA_ROUTE = '/api/metadata';
export const API_DETAIL_METADATA_ROUTE = (id: string) =>
  `${API_METADATA_ROUTE}/${id}`;
export const API_CATEGORY_METADATA_ROUTE = (id: string) =>
  `${API_METADATA_ROUTE}/category/${id}`;

export const API_PROFILE_ROUTE = '/profile';
export const API_USER_INVITATE_ROUTE = `${API_USER_ROUTE}/sendInvation`;

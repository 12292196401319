// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
}

.clean-layers-button:hover,
.close-button:hover {
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/Components/RulerModal/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;;EAEE,WAAW;AACb","sourcesContent":[".modal-content {\n  background-color: white;\n  padding: 16px;\n  border-radius: 8px;\n  width: 500px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.modal-title {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid #ddd;\n  padding-bottom: 8px;\n  margin-bottom: 16px;\n  font-size: 20px;\n  font-weight: 600;\n}\n\n.clean-layers-button:hover,\n.close-button:hover {\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

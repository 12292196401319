import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { DefaultLayout } from '../layout/DefaultLayout';
import { FormPageLayout } from '../layout/FormPageLayout';
import { AuthContext } from '../context/AuthContext';
import {
  About,
  Home,
  MetadataCatalog,
  MetadataDetail,
  MetadataCatalogByCategory,
  Reports,
  // SignUp,
  Login,
  UserEnable,
  SendPasswordRecoveryEmail,
  RecoveryPassword,
  InviteUser,
  SignUpWithToken,
} from '../pages';
import {
  CATEGORY_METADATA_ROUTE,
  DETAIL_METADATA_ROUTE,
  METADATA_ROUTE,
} from '../global/constants/routes/metadataConstants';
import {
  ENABLE_ROUTE,
  INVITE_USER_ROUTE,
  RECOVERY_PASSWORD_ROUTE,
  REGISTER_ROUTE,
} from '../global/constants/routes/userConstants';
// import { LOGIN_ROUTE } from '../global/constants/routes/defaultConstants';

export function Router() {
  const { signed } = useContext(AuthContext);
  return (
    <Routes>
      {signed && (
        <>
          <Route path="/" element={<DefaultLayout />}>
            <Route path="/" element={<Home />} />
            <Route
              path="/relatorios"
              element={signed ? <Reports /> : <Login />}
            />
            <Route
              path={METADATA_ROUTE}
              element={signed ? <MetadataCatalog /> : <Login />}
            />
            <Route
              path={DETAIL_METADATA_ROUTE(':pk')}
              element={<MetadataDetail />}
            />
            <Route
              path={CATEGORY_METADATA_ROUTE(':category')}
              element={<MetadataCatalogByCategory />}
            />
            <Route path="/sobre" element={<About />} />
          </Route>
          <Route path="/" element={<FormPageLayout />}>
            <Route path={INVITE_USER_ROUTE} element={<InviteUser />} />
          </Route>
        </>
      )}
      <Route path="/" element={<FormPageLayout />}>
        {/* <Route path={REGISTER_ROUTE('')} element={<SignUp />} /> */}
        <Route path={REGISTER_ROUTE(':token')} element={<SignUpWithToken />} />
        <Route path={ENABLE_ROUTE(':token')} element={<UserEnable />} />
        <Route path={'/login'} element={<Login />} />
        <Route path={'/'} element={<Login />} />
        <Route
          path={RECOVERY_PASSWORD_ROUTE()}
          element={<SendPasswordRecoveryEmail />}
        />
        <Route
          path={RECOVERY_PASSWORD_ROUTE(':token')}
          element={<RecoveryPassword />}
        />
      </Route>
    </Routes>
  );
}

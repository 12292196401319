import React, { Dispatch, SetStateAction } from 'react';
import { Tabs, Tab, Box, IconButton, Button } from '@mui/material';
import LayersIcon from '@mui/icons-material/LayersOutlined';
import MapIcon from '@mui/icons-material/MapOutlined';
import ScenarioIcon from '@mui/icons-material/FilterFramesOutlined';
import GeoLayersContent from '../GeoLayersModal';
import ScenariosModal from '../ScenariosModal';
import { Overlay, ModalContainer } from './styles';
import MapViewModal from '../MapViewModal';

interface TabComponentProps {
  selectedTab: number;
  setSelectedTab: Dispatch<SetStateAction<number>>;
  onClose: () => void;
}

export default function TabComponent({
  selectedTab,
  setSelectedTab,
  onClose,
}: TabComponentProps) {
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return <GeoLayersContent />;
      case 1:
        return <ScenariosModal />;
      case 2:
        return <MapViewModal />;
      default:
        return null;
    }
  };

  return (
    <Overlay onClick={onClose}>
      <ModalContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '400px',
          backgroundColor: '#fff',
          left: 0,
          marginTop: '70px',
          height: '580px',
          overflowY: 'auto',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Drawer Tabs"
          variant="fullWidth"
          sx={{
            marginTop: '15px',
            backgroundColor: '#fff',
            borderBottom: 'none',
            '.Mui-selected': {
              color: '#9FC131 !important',
            },
            '.MuiTabs-indicator': {
              backgroundColor: '#9FC131 !important',
            },
          }}
        >
          <Tab
            icon={<LayersIcon fontSize="medium" />}
            label="Camadas"
            iconPosition="start"
            sx={{
              fontWeight: '600',
              fontSize: '10px',
              minHeight: '0px !important',
            }}
          />
          <Tab
            icon={<ScenarioIcon />}
            label="Cenários"
            iconPosition="start"
            sx={{
              fontWeight: '600',
              fontSize: '10px',
              minHeight: '0px !important',
            }}
          />
          <Tab
            icon={<MapIcon />}
            label="Mapa Base"
            iconPosition="start"
            sx={{
              fontWeight: '600',
              fontSize: '10px',
              minHeight: '0px !important',
            }}
          />
        </Tabs>
        <Box
          p={2}
          sx={{
            width: '100%',
            overflowY: 'auto',
            flexGrow: 1,
          }}
        >
          {renderTabContent()}
        </Box>
      </ModalContainer>
    </Overlay>
  );
}

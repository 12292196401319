import React, { useContext } from 'react';
import { FormContainer, FormTitle } from '../../Components';
import { useNavigate, useParams } from 'react-router-dom';
import { LOGIN_ROUTE } from '../../global/constants/routes/defaultConstants';
import { useFormik } from 'formik';
import { ApiEnableUser } from '../../services/api';
import { AlertContext } from '../../context/AlertContext';

interface CustomError extends Error {
  msg: string;
}

export const UserEnable: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();

  const handleEnableUser = async () => {
    try {
      if (token) {
        const { message } = await ApiEnableUser(token);
        await setAlertMessage('success', message);
        navigate(LOGIN_ROUTE);
      }
    } catch (error) {
      setAlertMessage('error', (error as CustomError).msg);
    }
  };

  const enableForm = useFormik({
    initialValues: {},
    onSubmit: handleEnableUser,
  });

  const handleBackClick = () => {
    window.location.href = LOGIN_ROUTE;
  };

  return (
    <FormContainer
      onSubmit={enableForm.handleSubmit}
      onBack={handleBackClick}
      submitLabel="Confimar"
    >
      <FormTitle>Para habilitar seu usuário clique em confirmar.</FormTitle>
    </FormContainer>
  );
};

import { CheckboxTreeView } from '../CheckboxTreeView';
import { CleanLayersButton, ModalContent, ModalTitle } from './styles';
import { mockLayers } from './layers';
import { useLayers } from '../../context/LayersContext';

interface LayersModalProps {
  show: boolean;
  onClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

export function LayersModal({ show, onClose }: LayersModalProps) {
  const { cleanCheckedLayers } = useLayers();

  if (!show) {
    return null;
  }

  return (
    <div
    // style={{
    //   display: 'flex',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    //   position: 'fixed',
    //   top: 0,
    //   left: 0,
    //   right: 0,
    //   bottom: 0,
    //   zIndex: 1000,
    // }}
    >
      <ModalContent>
        <ModalTitle>
          Camadas
          <CleanLayersButton onClick={() => cleanCheckedLayers()}>
            Limpar camadas
          </CleanLayersButton>
        </ModalTitle>
        <CheckboxTreeView data={mockLayers} />
      </ModalContent>
    </div>
  );
}

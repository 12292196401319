import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CheckboxTreeView } from '../CheckboxTreeView';
import {
  ModalContent,
  ModalTitle,
  CleanLayersButton,
} from './styles';
import { geoLayers } from './layers';
import { useLayers } from '../../context/LayersContext';

interface ScenariosModal {
  show: boolean;
  onClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

export function ScenariosModal({ show, onClose }: ScenariosModal) {
  const { cleanCheckedLayers } = useLayers();

  return show ? (
    <div
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      // style={{
      //   display: 'flex',
      //   alignItems: 'center',
      //   justifyContent: 'center',
      //   position: 'fixed',
      //   top: 0,
      //   left: 0,
      //   bottom: 0,
      //   zIndex: 1000,
      // }}
    >
      <ModalContent>
        <ModalTitle>
          Cenários (GeoServer)
          <CleanLayersButton onClick={cleanCheckedLayers}>
            Limpar Camadas
          </CleanLayersButton>
        </ModalTitle>
        <CheckboxTreeView data={geoLayers} />
      </ModalContent>
    </div>
  ) : null;
}

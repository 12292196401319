import styled from 'styled-components';

export const ModalContent = styled.div`
  position: absolute;
  top: 80px;
  gap: 32px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 24px;
  z-index: 1001;
  border-radius: 4px;
`;

export const ModalHeader = styled.p`
  font-size: ${({ theme }) => theme.typography.size.header};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  padding-bottom: 20px;
`;

export const ModalTitle = styled.p`
  font-size: ${({ theme }) => theme.typography.size.title};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  padding-bottom: 10px;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  background-color: transparent;
  border: none;
  top: 18px;
  right: 24px;
  z-index: 1000;
  font-size: 15px;
  font-weight: 600;
`;

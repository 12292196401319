import { AppBar, autocompleteClasses } from '@mui/material';
import govpe from './assets/govpe.png';
import senai from './assets/senai.png';
import isitics from './assets/isitics.png';
import { Box } from '@mui/system';

export function Footer() {
  const buildDate = process.env.REACT_APP_BUILD_DATE || 'Data não disponível';
  return (
    <AppBar
      position="fixed"
      sx={{
        top: 'auto',
        backgroundColor: 'white',
        height: '5.5rem',
        bottom: '0rem',
      }}
    >
      <Box
        paddingX={'2rem'}
        width={'100%'}
        height={'100%'}
        display={'flex'}
        flexDirection={'row'}
        gap={2}
        alignItems={'center'}
      >
        <img
          src={senai}
          alt="Senai"
          style={{
            width: '7.5rem',
            height: '2.5rem',
            top: '63.73875rem',
            left: '2rem',
          }}
        />
        <img
          src={isitics}
          alt="Isitics"
          style={{
            height: '3.85rem',
            top: '63.73875rem',
            left: '2rem',
          }}
        />
        {/* <img
          src={govpe}
          alt="GovPE"
          style={{
            width: '8.75rem',
            height: '3.75rem',
            top: '62.73875rem',
            left: '11.779375rem',
          }}
        /> */}
        <span style={{ marginLeft: 'auto', color: 'black' }}>
          Versão beta atualizada em: {buildDate}
        </span>
      </Box>
    </AppBar>
  );
}

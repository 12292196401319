// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-legend {
  scrollbar-gutter: stable;
}
.info-legend::-webkit-scrollbar {
  width: 4px;
}
.info-legend::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}
.info-legend::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
`, "",{"version":3,"sources":["webpack://./src/Components/LegendComponent/styles.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;AACA;EACE,UAAU;AACZ;AACA;EACE,qCAAqC;EACrC,kBAAkB;AACpB;AACA;EACE,oCAAoC;AACtC","sourcesContent":[".info-legend {\n  scrollbar-gutter: stable;\n}\n.info-legend::-webkit-scrollbar {\n  width: 4px;\n}\n.info-legend::-webkit-scrollbar-thumb {\n  background-color: rgba(0, 0, 0, 0.16);\n  border-radius: 5px;\n}\n.info-legend::-webkit-scrollbar-thumb:hover {\n  background-color: rgba(0, 0, 0, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

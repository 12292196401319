export const REGISTER_ROUTE = (token?: string) => {
  const defaultRoute = '/cadastre-se';
  if (token) {
    return `${defaultRoute}/${token}`;
  }
  return defaultRoute;
};
export const INVITE_USER_ROUTE = '/convidar_usuario';
export const ENABLE_ROUTE = (token: string) => `/habilitar_usuario/${token}`;
export const RECOVERY_PASSWORD_ROUTE = (token?: string) => {
  const defaultRoute = '/recuperar_senha';
  if (token) {
    return `${defaultRoute}/${token}`;
  }
  return defaultRoute;
};

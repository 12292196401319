import React from 'react';
import {
  MetadataItemComponent,
  MetadataPreviewProps,
} from './MetadataItemComponent';
import { StyledListContainer } from './styles';

export type MetadataListComponentProps = {
  metadataList: MetadataPreviewProps[];
};

const MetadataListComponent: React.FC<MetadataListComponentProps> = ({
  metadataList,
}) => {
  return (
    <StyledListContainer>
      {Array.isArray(metadataList) &&
        metadataList.map((item: MetadataPreviewProps, index: number) => {
          return <MetadataItemComponent key={index} metadataItem={item} />;
        })}
    </StyledListContainer>
  );
};

export default MetadataListComponent;

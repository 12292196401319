export const layersDict = [
  // CENARIOS
  {
    title: 'Infraestrutura e Mobilidade',
    layerName: 'pe_infraestrutura_e_mobilidade',
    workspace: 'Facepe_2',
  },
  {
    title: 'Pernambuco e Suas águas - Infraestrutura Hídrica',
    layerName: 'Pernambuco_e_suas_Aguas',
    workspace: 'Facepe_2',
  },
  {
    title: 'Informações Socioambientais',
    layerName: 'pe_socioambiental',
    workspace: 'Facepe_2',
  },
  {
    title: 'Bases de Combustíveis - Cenários',
    layerName: 'bases_de_combustiveis',
    workspace: 'Facepe_2',
  },
  {
    title: 'Sistema Elétrico e Potencial Eólico',
    layerName: 'pe_eletrico_e_eolico',
    workspace: 'Facepe_2',
  },
  {
    title: 'Sistema Elétrico e Potencial Solar',
    layerName: 'eletrico_e_solar',
    workspace: 'Facepe_2',
  },
  // DADOS ESTRATÉGICOS
  {
    title: 'Rede Gasoduto (Aço)',
    layerName: 'copergas_aco',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Rede Gasoduto (PEAD)',
    layerName: 'copergas_pead',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Rede Gasoduto (Cobre Revestido)',
    layerName: 'copergas_cobre_revestido',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Consumo de Energia Elétrica 2022 (Mwh)',
    layerName: 'consumo_energia_eletrica_2022',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Empresas com resíduos para Amônia',
    layerName: 'empresas_amonia',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Empresas com resíduos para Hidrogênio',
    layerName: 'empresas_hidrogenio',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Empresas com resíduos para Biomassa',
    layerName: 'empresas_biomassa',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Aterros Sanitários',
    layerName: 'aterros_sanitarios',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Produto Interno Bruto (PIB) - Municipios Pernambuco 2021',
    layerName: 'municipios_pib_apl',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Áreas Urbanas (Cidades e Vilas)',
    layerName: 'areas_urbanizadas_2019',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Bases de estações Fluviométricas',
    layerName: 'estacoes_fluviometricas',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Bases de estações Pluviométricas',
    layerName: 'estacoes_pluviometricas',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Bases de estações Anemométricas - AMA',
    layerName: 'torres_anenometricas_ama',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Bases de estações Anemométricas - SONDA',
    layerName: 'torres_anenometricas_sonda',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Rede CELPE - Alta Tensão',
    layerName: 'celpe_alta_tensao',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Rede CELPE - Baixa Tensão',
    layerName: 'celpe_baixa_tensao',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Rede CELPE - Média Tensão',
    layerName: 'celpe_media_tensao',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Rede CELPE - Estrutura de Suporte',
    layerName: 'celpe_estrutura',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  // SUAPE
  {
    title: 'Área total do Porto',
    layerName: 'area_total_porto_de_suape',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Porto Organizado',
    layerName: 'area_suape_organizado',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  // UNIDADE ADMINSTRATIVA
  {
    title: 'Municípios',
    layerName: 'municipios_pib_apl',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  // {
  //   title: 'Estações solarimétricas',
  //   layerName: 'empresas_',
  //   workspace: 'Facepe_2',
  //   type: 'WMS',
  // },
  // CLIMATOLOGIA
  {
    title: 'Insolação (horas)',
    layerName: 'INSO_PE',
    workspace: 'Facepe_2',
    type: 'RASTER',
  },

  {
    title: 'Temperatura Média Anual (INMET)',
    layerName: 'TMC_PE_ANUAL',
    workspace: 'develop',
    type: 'RASTER',
  },
  {
    title: 'Umidade Relativa do Ar',
    layerName: 'UR_PE_ANUAL',
    workspace: 'develop',
    type: 'RASTER',
  },
  {
    title: 'Precipitação',
    layerName: 'mapa_prec_pe2',
    workspace: 'develop',
    type: 'RASTER',
  },
  // POTENCIAL EÓLICO
  {
    title: 'Velocidade do Vento (10m_s)',
    layerName: 'BRA_wind-speed_10m_PE',
    workspace: 'Facepe_2',
    type: 'RASTER',
  },
  {
    title: 'Velocidade do Vento (50m_s)',
    layerName: 'BRA_wind-speed_50m_PE',
    workspace: 'Facepe_2',
    type: 'RASTER',
  },
  {
    title: 'Velocidade do Vento (100m_s)',
    layerName: 'BRA_wind-speed_100m_PE',
    workspace: 'Facepe_2',
    type: 'RASTER',
  },
  {
    title: 'Velocidade do Vento (150m_s)',
    layerName: 'BRA_wind-speed_150m_PE',
    workspace: 'Facepe_2',
    type: 'RASTER',
  },
  {
    title: 'Velocidade do Vento (200m_s)',
    layerName: 'BRA_wind-speed_200m_PE',
    workspace: 'Facepe_2',
    type: 'RASTER',
  },
  // WMS LAYERS SISTEMA ELETRICO EXISTENTE
  {
    title: 'Usina Fotovoltáicas Existentes',
    layerName: 'ufv_base_existente',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Usina de Combustível Fóssil (Termelétricas)',
    layerName: 'ute_fossil_base_existente',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Centrais Geradoras Hidrelétricas',
    layerName: 'cgh_base_existente',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Linhas de Transmissão Existentes',
    layerName: 'linhas_de_transmissao_base_existente',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Subestações Existentes',
    layerName: 'subestacoes_base_existente',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Parques Eólicos Existentes',
    layerName: 'eol_base_existente',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Usinas Hidrelétricas Existentes',
    layerName: 'uhe_base_existente',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  // WMS LAYERS SISTEMA ELETRICO PLANEJADO
  {
    title: 'Parques Eólicos Planejados',
    layerName: 'eol_expansao_planejada',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Linhas de Transmissão Planejadas',
    layerName: 'linhas_de_transmissao_expansao_planejada',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Subestações Planejadas',
    layerName: 'subestacoes_expansao_planejada',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Usinas Fotovoltáicas Planejadas',
    layerName: 'ufv_expansao_planejada',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  // wfs COMBUSTIVEIS
  {
    title: 'Petróleo e derivados',
    layerName: 'terminais_de_petroleo_e_derivados',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Regasificação Gás Natural Liquefeito (GNL)',
    layerName: 'terminais_gnl',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Gás Liquefeito de Petróleo (GLP)',
    layerName: 'base_glp',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Exploração e Produção de Hidrocarbonetos',
    layerName: 'exploracao_e_producao_de_hidrocarbonetos',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Bases de Combustíveis',
    layerName: 'base_combustiveis',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Plantas de Etanol',
    layerName: 'planta_etanol',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  // SOCIOAMBIENTAL
  {
    title: 'Unidades de Conservação Ambiental',
    layerName: 'unidades_conservacao_ambiental',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Territórios quilombolas',
    layerName: 'area_quilombola',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Região Semiárida (Poligono das Secas)',
    layerName: 'poligono_das_secas',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Regiões de importância Biológica (Caatinga)',
    layerName: 'areas_prioritarias_caartinga',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Área prioritária para Conservação da Mata Atlântica',
    layerName: 'areas_prioritarias_mata_atlantica',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Unidades Agrícolas Instaladas pelo INCRA',
    layerName: 'assentamentos_publicos',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Terras indígenas (Homologadas)',
    layerName: 'terras_indigenas_homologadas',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Reserva da Biosfera',
    layerName: 'reservas_da_biosfera',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Sítios Arqueológicos',
    layerName: 'sitios_Arqueologicos',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Aves Migratórias (Áreas Ameçadas)',
    layerName: 'aves_migratorias_areas_ameacadas',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Aves Migratórias (Áreas de concentração)',
    layerName: 'aves_migratorias_areas_de_concentracao',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Cavernas',
    layerName: 'cavernas_pe',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Unidades Territoriais Urbanas',
    layerName: 'unidades_territoriais_urbanas',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Zonemaneto Agroecológico',
    layerName: 'zoneamento_ecologico_pe',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  // WFS TRANSPORTE
  {
    title: 'Ferrovias Planejadas',
    layerName: 'ferrovias_planejadas',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Pátios Ferroviários',
    layerName: 'patios_ferroviarios',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Gasodutos de Distribuição',
    layerName: 'gasoduto_distribuicao_pe',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Gasodutos de Transporte',
    layerName: 'gasoduto_transporte_pe',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Rodovias Federais',
    layerName: 'rodovia_federal',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Rodovias Municipais',
    layerName: 'rodovia_municipais',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Rodovias Estaduais',
    layerName: 'rodovia_estadual',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Aerôdromo e Heliponto',
    layerName: 'aerodromos_helipontos',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  {
    title: 'Aeroportos',
    layerName: 'pe_aeroportos',
    workspace: 'Facepe_2',
    type: 'WMS',
  },
  // WFS POTENCIAL SOLAR
  {
    title: 'Irradiação Global Horizontal Anual (Wh/m².dia)',
    layerName: 'Irradiacao_Global_Horizontal_Anual',
    workspace: 'Facepe_2',
    type: 'WMS-solar',
  },
  {
    title: 'Irradiação Difusa Anual (Wh/m².dia)',
    layerName: 'Iradiacao_Difusa_Anual',
    workspace: 'Facepe_2',
    type: 'WMS-solar',
  },
  {
    title: 'Irradiação Direta Normal Anual (Wh/m².dia)',
    layerName: 'Iradiacao_Direta_Normal_Anual',
    workspace: 'Facepe_2',
    type: 'WMS-solar',
  },
  {
    title: 'Irradiação no Plano Inclinado Anual (Wh/m².dia)',
    layerName: 'Irradiacao_Plano_Inclinado_Anual',
    workspace: 'Facepe_2',
    type: 'WMS-solar',
  },
  {
    title: 'Irradiação PAR (Photosynthetically-active radiation) (Wh/m².dia)',
    layerName: 'Irradiacao_PAR',
    workspace: 'Facepe_2',
    type: 'WMS-solar',
  },
  {
    title: 'Irradiação Média Global no Inverno (Março a agosto)',
    layerName: 'Irradiacao_Media_Global_no_Inverno',
    workspace: 'Facepe_2',
    type: 'WMS-solar',
  },
];

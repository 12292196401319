import { useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import {
  Footer,
  GeoLayersModal,
  LayersModal,
  MapActionBar,
  MapComponent,
  MapViewModal,
  Navbar,
  ScenariosModal,
  UploadFileModal,
} from '../../Components';
import { MeasureComponent } from '../../Components/MeasureComponent';
import RulerModal from '../../Components/RulerModal';
import { useRulerModalContext } from '../../context/RulerContext';
import { HomeContainer } from '../styles';

export function Home() {
  const [showGeoLayersModal, setShowGeoLayersModal] = useState(false);
  const [showLayersModal, setShowLayersModal] = useState(false);
  const [showMapViewModal, setShowMapViewModal] = useState(false);
  const [showScenariosModal, setShowScenariosModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showToolModal, setShowToolModal] = useState(false);

  const { activeFunction } = useRulerModalContext();

  return (
    <HomeContainer>
      <Navbar />
      <MapComponent>
        <MapActionBar
          showGeoLayersModal={showGeoLayersModal}
          handleGeoLayersModal={setShowGeoLayersModal}
          showLayersModal={showLayersModal}
          handleLayersModal={setShowLayersModal}
          showMapViewModal={showMapViewModal}
          handleMapViewModal={setShowMapViewModal}
          handleScenariosView={setShowScenariosModal}
          showScenariosModal={showScenariosModal}
          setShowUploadFileModal={setShowUploadFileModal}
          showUploadFileModal={showUploadFileModal}
          handleToolModal={setShowToolModal}
          showToolModal={showToolModal}
        />
        <FeatureGroup>
          {activeFunction === 'LOCATION' && (
            <EditControl
              position="topright"
              draw={{
                polyline: false,
                polygon: false,
                rectangle: false,
                circle: false,
                marker: true,
                circlemarker: false,
              }}
            />
          )}
          {activeFunction === 'DRAW' && (
            <EditControl
              position="topright"
              draw={{
                polyline: false,
                polygon: true,
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false,
              }}
            />
          )}
          {activeFunction === 'MEASURE' && <MeasureComponent />}
        </FeatureGroup>
      </MapComponent>

      <RulerModal show={showToolModal} onClose={setShowToolModal} />
      <MapViewModal show={showMapViewModal} onClose={setShowMapViewModal} />
      <GeoLayersModal
        show={showGeoLayersModal}
        onClose={setShowGeoLayersModal}
      />
      <LayersModal show={showLayersModal} onClose={setShowLayersModal} />
      <ScenariosModal
        show={showScenariosModal}
        onClose={setShowScenariosModal}
      />
      <UploadFileModal
        show={showUploadFileModal}
        onClose={setShowUploadFileModal}
      />
      <Footer />
    </HomeContainer>
  );
}

export default Home;

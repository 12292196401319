import React from 'react';
import { Box } from '@mui/material';
import { EnergyTypographyTitle, EnergyTypographyData } from './styles';
import WindPowerIcon from '@mui/icons-material/WindPowerOutlined';
import SolarPowerIcon from '@mui/icons-material/LightModeOutlined';

interface EnergyProps {
  latitude: string | null;
  longitude: string | null;
  address: string | null;
  globalIrradiation: string | null;
  difuseIrradiation: string | null;
}

export const Energy: React.FC<EnergyProps> = ({
  latitude,
  longitude,
  address,
  globalIrradiation,
  difuseIrradiation,
}) => {
  console.log(latitude);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#F8F9FA',
          borderRadius: '8px',
          padding: '16px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <EnergyTypographyTitle>Latitude</EnergyTypographyTitle>
          <EnergyTypographyData>{latitude || '-'}</EnergyTypographyData>
        </Box>
        <Box sx={{ textAlign: 'center', marginX: '24px' }}>
          <EnergyTypographyTitle>Longitude</EnergyTypographyTitle>
          <EnergyTypographyData>{longitude || '-'}</EnergyTypographyData>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <EnergyTypographyTitle>Endereço</EnergyTypographyTitle>
          <EnergyTypographyData>{address || '-'}</EnergyTypographyData>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          marginTop: '10px',
          gap: '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            backgroundColor: '#015C53',
            borderRadius: '8px',
            padding: '20px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            width: '300px',
            height: '100px',
            gap: '8px',
          }}
        >
          <SolarPowerIcon fontSize="large" sx={{ color: '#DCCA28' }} />
          <div style={{ color: 'white', fontSize: '14px' }}>Solar</div>
          {/* <div
            style={{ marginTop: '50px', color: 'white', marginRight: '30px' }}
          >
            Clique para alterar
          </div> */}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#F8F9FA',
            borderRadius: '8px',
            padding: '10px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            width: '150px',
            height: '100px',
          }}
        >
          <EnergyTypographyTitle>
            Radiação solar global horizontal
          </EnergyTypographyTitle>
          <EnergyTypographyData>
            {globalIrradiation ? `${globalIrradiation} Wh/m²` : '-'}
          </EnergyTypographyData>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#F8F9FA',
            borderRadius: '8px',
            padding: '10px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            width: '150px',
            height: '100px',
          }}
        >
          <EnergyTypographyTitle>
            Radiação solar horizontal difusa
          </EnergyTypographyTitle>
          <EnergyTypographyData>
            {difuseIrradiation ? `${difuseIrradiation}  Wh/m²` : '-'}
          </EnergyTypographyData>
        </Box>
      </Box>
    </div>
  );
};

//<WindPowerIcon fontSize="large" sx={{ color: '#D3B0FF' }} />
//<SolarPowerIcon fontSize="large" sx={{ color: '#DCCA28' }} />

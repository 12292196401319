import { AUTHORIZATION_KEY } from '../constants/storageConstants';
import {
  removeItemStorage,
  setItemStorage,
  getItemStorage,
} from './storageProxy';

export const unsetAuthorizationToken = () =>
  removeItemStorage(AUTHORIZATION_KEY);

export const setAuthorizationToken = (token?: string) => {
  if (token) {
    setItemStorage(AUTHORIZATION_KEY, token);
  }
};

export const getAuthorizationToken = () => getItemStorage(AUTHORIZATION_KEY);

export const getFormattedAuthorizationToken = () =>
  `token ${getItemStorage(AUTHORIZATION_KEY)}`;

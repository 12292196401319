import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton } from '@mui/material';
import L, { Control } from 'leaflet';
import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useMap } from 'react-leaflet';
import { useLayers } from '../../context/LayersContext';
import { layersDict } from '../../global/constants/layersDict';
import { LegendData } from '../LegendData';
import {
  LegendContainer,
  LegendHeader,
  LegendLabel,
  LegendTitle,
} from './styles';
import './styles.css';
export function Legend() {
  const { layers } = useLayers();
  const map = useMap();
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (map && layers.length > 0) {
      const legend = new Control({ position: 'bottomleft' });
      legend.onAdd = () => {
        const div = L.DomUtil.create('div');
        createRoot(div).render(
          <LegendContainer expanded={expanded}>
            <LegendHeader>
              <LegendTitle> Camadas Selecionadas </LegendTitle>
              <IconButton onClick={() => toggleExpanded()}>
                {expanded === false ? (
                  <KeyboardArrowUpIcon fontSize="large" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="large" />
                )}
              </IconButton>
            </LegendHeader>
            {layersDict.map((layer, index) => {
              if (layers.includes(layer.title)) {
                return (
                  <>
                    <LegendLabel>{layer.title}</LegendLabel>
                    <LegendData
                      layer={layer.layerName}
                      workspace={layer.workspace}
                      key={index}
                    />
                  </>
                );
              }
            })}
          </LegendContainer>
        );
        return div;
      };
      legend.addTo(map);
      return () => {
        map.removeControl(legend);
      };
    }
  }, [map, layers, expanded]);

  return null;
}

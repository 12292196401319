import { Outlet } from 'react-router-dom';
import { FormPageLayoutContainer, StyledLogo } from './styles';
import { Footer } from '../../Components';
import { Link } from '@mui/material';
import { HOME_ROUTE } from '../../global/constants/routes/defaultConstants';

export function FormPageLayout() {
  return (
    <FormPageLayoutContainer>
      <Link href={HOME_ROUTE}>
        <StyledLogo />
      </Link>
      <Outlet />
      <Footer />
    </FormPageLayoutContainer>
  );
}

import axios from 'axios';

// export const geoServerBaseUrl = process.env.REACT_APP_GEOSERVER_BASE_URL;
// export const geoServerBaseUrl = 'https://geoserver.isitics.com/geoserver';
export const geoServerBaseUrl = 'https://dev.geoserver.isitics.com/geoserver';

export const geoServerDefaultParams = {
  service: 'WFS',
  version: '1.0.0',
  request: 'GetFeature',
  maxFeatures: 600,
  outputFormat: 'application/json',
};

export const geoServerPopupDefaultParams = {
  SERVICE: 'WMS',
  VERSION: '1.1.1',
  REQUEST: 'GetFeatureInfo',
  FORMAT: 'text/html',
  TRANSPARENT: 'true',
  // QUERY_LAYERS: 'Facepe_2:municipios_pib_apl',
  STYLES: '',
  // LAYERS: 'Facepe_2:municipios_pib_apl',
  exceptions: 'application/vnd.ogc.se_inimage',
  INFO_FORMAT: 'text/html',
  FEATURE_COUNT: '50',
  X: '50',
  Y: '50',
  SRS: 'EPSG:4674',
  WIDTH: '101',
  HEIGHT: '101',
  // BBOX: '-35.212554931640625,-8.4100341796875,-34.935150146484375,-8.13262939453125',
};

export const geoServerLegendDefaultParams = {
  service: 'wms',
  version: '1.0.0',
  request: 'GetLegendGraphic',
  format: 'image/png',
  width: 20,
  height: 20,
};

export const geoServer = axios.create({
  baseURL: geoServerBaseUrl,
});

import React from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import RouteIcon from '@mui/icons-material/Route';
import DrawIcon from '@mui/icons-material/Create';
import PrintIcon from '@mui/icons-material/Print';
import RulerComponent from '../RulerComponent';
import './index.css';

interface RulerModalProps {
  show: boolean;
  onClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

export function RulerModal({ show, onClose }: RulerModalProps) {
  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
      }}
    >
      <div className="modal-content" style={{
        position: 'absolute',
        top: '380px',
        left: '300px',
        transform: 'translate(-50%, -50%)',
        width: 'fit-content',
        maxHeight: '80vh',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}>
        <div className="modal-title">Ferramentas do Mapa</div>
        <div
          style={{
            position: 'relative',
            paddingTop: '8px',
            display: 'flex',
            gap: '16px',
            alignItems: 'flex-start',
          }}
        >
          <RulerComponent
            iconLabel="Localização"
            icon={PlaceIcon}
            rulerAction={'LOCATION'}
          />
          <RulerComponent
            iconLabel="Medir"
            icon={RouteIcon}
            rulerAction={'MEASURE'}
          />
          <RulerComponent
            iconLabel="Desenhar"
            icon={DrawIcon}
            rulerAction="DRAW"
          />
          <RulerComponent
            iconLabel="Imprimir"
            icon={PrintIcon}
            rulerAction="PRINT"
          />
        </div>
      </div>
    </div>
  );
}

export default RulerModal;

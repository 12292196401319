import { useLayers } from '../../context/LayersContext';
import { Tiles } from '../../global/constants/tiles';
import { TileLayer } from 'react-leaflet';

export default function MapTileLayer() {
  const { tile } = useLayers();

  switch (tile) {
    case Tiles.CartoDB:
      return <TileLayer url={tile} maxZoom={28} attribution="CartoDB" />;
    case Tiles.ESRISatelite:
      return <TileLayer url={tile} maxZoom={28} attribution="ESRISatelite" />;
    case Tiles.ESRIFisico:
      return <TileLayer url={tile} maxZoom={15} attribution="ESRIFisico" />;
    case Tiles.GoogleSatelite:
      return <TileLayer url={tile} maxZoom={20} attribution="GoogleSatelite" />;
    default:
      return <TileLayer url={tile} maxZoom={28} attribution="CartoDB" />;
  }
}

import baseMapsCarto from '../../../assets/basemaps/basemaps_carto.png';
import baseMapsEsri from '../../../assets/basemaps/basemaps_esri.png';
import baseMapsFisico from '../../../assets/basemaps/basemaps_fisico.png';
import baseMapsGoogle from '../../../assets/basemaps/basemaps_google.png';
import { FormGroup } from '@mui/material';
import { ModalTitle } from './styles';
import { Tiles } from '../../../global/constants/tiles';
import { useLayers } from '../../../context/LayersContext';

export default function MapTilesModal() {
  const { tile, addNewTile } = useLayers();

  function MapTileImage({
    icon,
    tileSelected,
    tileName,
    onClick,
  }: {
    icon: string;
    tileSelected: string;
    tileName: string;
    onClick: () => void;
  }) {
    return (
      <div onClick={() => onClick()} style={{ cursor: 'pointer' }}>
        <img
          src={icon}
          alt={tileName}
          style={{
            width: '90px',
            height: '90px',
            borderRadius: '4px',
            marginRight: '10px',
            border: tile === tileSelected ? '3px solid #9FC131' : 'none',
          }}
        />
        <br />
        <p style={{ fontWeight: '500' }}>{tileName}</p>
      </div>
    );
  }

  return (
    <>
      <ModalTitle>Mapa Base</ModalTitle>
      <FormGroup row={true} sx={{ paddingBottom: '2rem' }}>
        <MapTileImage
          icon={baseMapsCarto}
          tileSelected={Tiles.CartoDB}
          tileName="Carto DB"
          onClick={() => addNewTile(Tiles.CartoDB)}
        />
        {/* <MapTileImage
          icon={baseMapsGoogle}
          tileSelected={Tiles.GoogleSatelite}
          tileName="Google Satélite"
          onClick={() => addNewTile(Tiles.GoogleSatelite)}
        /> */}
        <MapTileImage
          icon={baseMapsFisico}
          tileSelected={Tiles.ESRIFisico}
          tileName="ESRI Físico"
          onClick={() => addNewTile(Tiles.ESRIFisico)}
        />
        <MapTileImage
          icon={baseMapsEsri}
          tileSelected={Tiles.ESRISatelite}
          tileName="ESRI Satélite"
          onClick={() => addNewTile(Tiles.ESRISatelite)}
        />
      </FormGroup>
    </>
  );
}

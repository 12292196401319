import React from 'react';
import { useFiles } from '../../context/UploadedFilesContext';
import * as shapefile from 'shapefile';
import {
  ModalContainerUploadFile,
  ModalPaper,
  ModalHeaderUploadFile,
  ModalDescription,
  UploadButton,
} from './styles';

interface UploadFileModalType {
  show: boolean;
  onClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  leftPosition: string;
}

interface UploadFileModalType {
  show: boolean;
  onClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

export function UploadFileModal({ show, leftPosition }: UploadFileModalType) {
  const { addNewFile, cleanFiles } = useFiles();

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.type.includes('kml')) {
        handleUploadKML(file);
      }
      if (file.type.includes('json')) {
        handleGeoJsonFileChange(file);
      }
      if (file.type.includes('shape')) {
        handleShapefileFileChange(file);
      }
    }
  };

  const handleUploadKML = (file: any) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const kmlContent = event.target.result as string;
          const parser = new DOMParser();
          const kmlDocument = parser.parseFromString(kmlContent, 'text/xml');
          addNewFile({
            layer: kmlDocument,
            type: file.type,
          });
        }
      };
      reader.readAsText(file);
    }
  };

  const handleGeoJsonFileChange = (file: any) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const geoJsonContent = event.target.result as string;
          addNewFile({
            layer: JSON.parse(geoJsonContent),
            type: file.type,
          });
        }
      };
      reader.readAsText(file);
    }
  };

  const handleShapefileFileChange = (file: any) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        if (event.target) {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const data = await shapefile.read(arrayBuffer);
          addNewFile({
            layer: data,
            type: file.type,
          });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return show ? (
    <ModalContainerUploadFile
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{ left: leftPosition, transition: 'left 0.3s ease' }}
    >
      <ModalPaper>
        <ModalHeaderUploadFile>Importar um Arquivo</ModalHeaderUploadFile>
        <ModalDescription>
          Selecione um arquivo do tipo Shapefile ou KML correspondente à
          plataforma.
        </ModalDescription>
        <UploadButton
          as="label"
          style={{
            backgroundColor: '#9FC131',
            color: 'white',
            display: 'block',
            width: '100%',
            height: '50px',
            textAlign: 'center',
            lineHeight: '50px',
            cursor: 'pointer',
            borderRadius: '4px',
            gap: '10px',
          }}
        >
          FAZER UPLOAD
          <input
            id="fileInput"
            type="file"
            style={{ display: 'none' }}
            onChange={handleUpload}
            accept=".shp,.kml,.json"
          />
        </UploadButton>
        <UploadButton onClick={() => cleanFiles()} style={{ color: '#9fc131' }}>
          Remover camadas
        </UploadButton>
      </ModalPaper>
    </ModalContainerUploadFile>
  ) : null;
}

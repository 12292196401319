import React from 'react';
import { TextFieldProps } from '@mui/material';
import {
  StyledFormTextField,
  StyledFormTextFieldContainer,
  StyledFormTextFieldLabel,
} from './styles';

type CustomTextFieldMUIProps = TextFieldProps & {
  standartLabel: string;
  width?: string;
};

export const FormTextField: React.FC<CustomTextFieldMUIProps> = (props) => {
  const { standartLabel, width = '100%', ...defaultProps } = props;
  const optionalText = !props.required ? (
    <span style={{ color: '#707070' }}>(opcional)</span>
  ) : null;

  return (
    <StyledFormTextFieldContainer width={width}>
      <StyledFormTextFieldLabel variant="h3">
        {standartLabel && standartLabel} {optionalText}
      </StyledFormTextFieldLabel>
      <StyledFormTextField variant="outlined" {...defaultProps}>
        {defaultProps.children && defaultProps.children}
      </StyledFormTextField>
    </StyledFormTextFieldContainer>
  );
};

import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;

  img {
    width: 150px;
  }
`;

export const NavContainter = styled.nav`
  display: flex;
  justify-content: space-around;
  width: 60%;

  a {
    cursor: pointer;
    padding: 2.45rem 0;
    position: relative;
    text-decoration: none;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: 5px solid ${({ theme }) => theme.colors.greenDark};
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }

    &:hover,
    &:active,
    &.active {
      label {
        color: ${({ theme }) => theme.colors.greenDark};
        font-weight: 800;
      }

      &:after {
        transform: scaleX(1);
      }
    }
  }

  label {
    cursor: pointer;
    font-size: 1.3rem;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colors.grayLight};
    text-transform: uppercase;

    white-space: nowrap;
    padding: 0 0.4rem;
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  padding-right: 2rem;
  justify-content: flex-end;
  padding: 1.3rem;
  cursor: pointer;

  svg {
    width: 2.2rem;
    height: 2.2rem;
  }

  label {
    cursor: pointer;
    line-height: 21px;
    font-size: 1.3rem;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colors.grayLight};
  }

  &:hover {
    label {
      text-decoration: underline;
    }
  }
`;

const currentRoute = window.location.pathname;

export default {
  colors: {
    black: '#000000',
    mainBlue: '#0047E6',
    darkBlue: '#031F2A',
    lightBlue: '#AFEBF2',
    red: '#D71515',
    yellowShell: '#FBCC07',
    white: '#FFFFFF',
    greenLight: '#9FC131',
    greenDark: '#015C53',
    grayLight: '#61656D',
    grayLighter: '#9EA0A4',
    grayPale: '#D9D9D9',
    backgroundPrimary: currentRoute.includes('app/alt-data')
      ? '#292C31'
      : '#EDEDF4',
    backgroundSecondary: currentRoute.includes('app/alt-data')
      ? '#3B3F48'
      : '#F9F9FF',
    backgroundTertiary: '#F8FDFF',
  },
  typography: {
    weight: {
      regular: 500,
      bold: 600,
    },
    size: {
      header: '2rem',
      title: '1.8rem',
      text: '1.2rem',
    },
    fontFamily: 'Work Sans',
  },
};

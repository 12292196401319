import styled, { css } from 'styled-components';

export const ModalContent = styled.div`
  top: 380px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 24px;
  z-index: 1001;
  overflow-y: auto;
`;

export const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.typography.size.title};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
`;

export const CleanLayersButton = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.red};
    variant: outlined;
    background: none;
    border: none;
    font-family: ${theme.typography.fontFamily};
    font-size: 1.6rem;
    font-weight: 500;
    padding: 0.5rem;
    position: absolute;
    top: 1rem;
    right: 3rem;
    border-radius: 3px;

    &:hover {
      background-color: #0000000a;
    }
  `}
`;

export const mockLayers = [
  [
    'Informações Adicionais',
    [
      'Rede Copergás (Aço)',
      'Rede Copergás (PEAD)',
      'Rede Copergás (Cobre Revestido)',
      'Aterros Sanitários Ativos',
      'Consumo de Energia Elétrica 2022 (Mwh)',
      'Empresas com resíduos para Biomassa',
      'Estações solarimétricas',
    ],
  ],
  [
    'Infraestrutura Hídrica',
    [
      'Principais Rios',
      'Transposição Rio São Francisco (Eixo Norte)',
      'Transposição Rio São Francisco (Eixo Leste)',
      'Instalações portuárias',
      'Fluxos de Cabotagem',
      'Navegação Interior',
      'Instalações Portuárias',
    ],
  ],
  ['Suape', ['Área total porto de suape', 'Área porto organizado de suape']],
  [
    "APL'S (setores)",
    [
      'Caprino;Ovicultura',
      'Caprinovinocultura',
      'Ovinocaprinocultura;Pecuária',
      'Confecção de Roupas',
      'Fruticultura',
      'Pecuária',
      'Vinhos',
      'Agropecuária',
      'Renda Renascenca',
      'Tilapicultura',
      'Apicultura',
      'Avicultura',
      'Avicultura;Softwares',
    ],
  ],
  [
    'Socioambiental',
    [
      'Unidade de Conservação',
      'Territórios quilombolas',
      'Polígono das secas',
      'Área prioritária caatinga',
      'Área prioritária Mata Atlântica',
      'Assentamentos públicos',
      'Terras indígenas (Homologadas)',
      'Reserva da Biosfera',
      'Sítios Arqueológicos',
    ],
  ],
  [
    'Terminais de petróleo e gás',
    [
      'Petróleo e derivados',
      'Regaseificação Gás Natural Liquefeito',
      'Oleodutos e Terminais do Plano de Investimento em Logistica',
    ],
  ],
  [
    'Bases de Combustíveis',
    [
      'Gás Liquefeito de Petróleo (GLP)',
      'Exploração e Produção de Hidrocarbonetos',
      'Bases de Combustíveis',
      'Plantas de Etanol',
    ],
  ],
  [
    'Sistema Elétrico Planejado',
    [
      'Parques Eólicos - Sistema Elétrico Planejado',
      'Linhas de Transmissão - Sistema Elétrico Planejado',
      'Subestações - Sistema Elétrico Planejado',
      'Usinas Fotovoltaicas - Sistema Elétrico Planejado',
    ],
  ],
  [
    'Sistema Elétrico Existente',
    [
      'Usinas Fotovotaicas',
      'Usinas de combustível fóssil (Termelétricas)',
      'Centrais Geradoras Hidrelétricas',
      'Linhas de Transmissão',
      'Subestações',
      'Parques Eólicos',
      'Usinas Hidrelétricas',
    ],
  ],
  [
    'Infraestrutura de Transportes',
    [
      'Ferrovias planejadas',
      'Pátios Ferroviários',
      'Gasodutos de distribuição',
      'Gasodutos de transporte',
      'Rodovias Federais',
      'Rodovias Estaduais',
      'Outros trechos Rodoviários',
      'Aerôdromo e Heliponto',
      'AeroportosFerrovias planejadas',
      'Pátios Ferroviários',
      'Gasodutos de distribuição',
      'Gasodutos de transporte',
      'Rodovias Federais',
      'Rodovias Estaduais',
      'Outros trechos Rodoviários',
      'Aerôdromo e Heliponto',
      'Aeroportos',
    ],
  ],
  [
    'Potencial Eólico',
    [
      'Velocidade do Vento (50m)',
      'Velocidade do Vento (100m)',
      'Velocidade do Vento (150m)',
      'Velocidade do Vento (200m)',
    ],
  ],
  [
    'Potencial Solar',
    ['Radiação Global Anual', 'Radiação Direta Anual', 'Insolação Média Anual'],
  ],
];

import { Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledCategoryLabel = styled(Typography)`
  && {
    font-family: Work Sans;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 2.9325rem;
    text-align: left;
  }
`;

import { Button, Typography, Box } from '@mui/material';
import styled from 'styled-components';

export const StyledMetadataContainer = styled(Box)`
  && {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 50px;
    gap: 1rem;
    padding: 0rem 0rem 2rem;
  }
`;

export const StyledMetadataTextualContainer = styled(Box)`
  && {
    position: relative;
    display: flex;
    grid-column: span 9;
    gap: 1rem;
    flex-direction: column;
    border-radius: 1rem;
    padding: 2rem;
    background-color: #ffffff;
  }
`;

export const StyledMetadataTextualTitle = styled(Typography)`
  && {
    font-family: Work Sans;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2rem;
    text-align: left;
    color: #015c53;
  }
`;

export const StyledMetadataTextualSummary = styled(Typography)`
  && {
    margin: 1rem 0rem;
    text-aling: justify;
    font-family: Work Sans;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.46625rem;
    text-align: left;
    color: #000000;
  }
`;

export const StyledMetadataTextualItem = styled(Box)`
  && {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledMetadataTextualItemTitle = styled(Typography)`
  && {
    grid-column: span 1;
    font-family: Work Sans;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.46625rem;
    text-align: left;
  }
`;

export const StyledMetadataTextualItemValue = styled(Typography)`
  && {
    grid-column: span 1;
    font-family: Work Sans;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.46625rem;
    grid-column: span 1;
    width: fit-content;
  }
`;

export const StyledMetadataHighlightsContainer = styled(Box)`
  && {
    display: flex;
    grid-column: span 3;
    flex-direction: column;
  }
`;

export const StyledMetadataHighlightsImage = styled.img`
  && {
    width: 100%;
    heigth: auto;
    border-radius: 1rem;
  }
`;

interface DownloadButtonProps {
  target: string;
}

export const StyledMetadataHighlightsDownloadButton = styled(
  Button
)<DownloadButtonProps>`
  && {
    margin: 1rem 0rem;
    padding: 1.5rem 0rem;
    color: #ffffff;
    background-color: #9fc131;
    border-radius: 0.5rem;
    :hover {
      background-color: #7fa111;
    }
  }
`;

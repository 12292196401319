import styled from 'styled-components';
import { Popup } from 'react-leaflet';

export const StyledPopup = styled(Popup)`
  & .leaflet-popup-content-wrapper {
    width: 300px !important;
    height: 100% !important;
    overflow: auto;
  }
`;

import { CheckboxTreeView } from '../CheckboxTreeView';
import { CleanLayersButton, ModalContent, ModalTitle } from './styles';
import { geoLayers } from './layers';
import { useLayers } from '../../context/LayersContext';

interface GeoLayersModalProps {
  show: boolean;
  onClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

export function GeoLayersModal({ show, onClose }: GeoLayersModalProps) {
  const { cleanCheckedLayers } = useLayers();

  return show ? (
    <div
      className="modalGeo"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ModalContent>
        <ModalTitle>
          Camadas (GeoServer)
          <CleanLayersButton onClick={cleanCheckedLayers}>
            Limpar Camadas
          </CleanLayersButton>
        </ModalTitle>
        <CheckboxTreeView data={geoLayers} />
      </ModalContent>
    </div>
  ) : null;
}

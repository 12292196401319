import { createContext, useState, useContext, ReactNode } from 'react';
import { Tiles } from '../global/constants/tiles';

interface ILayersContext {
  addNewLayer(layer: string): void;
  layers: string[];
  addNewTile(tile: string): void;
  tile: string;
  cleanCheckedLayers(): void;
}

interface IPageProvider {
  children: ReactNode;
}

const LayersContext = createContext<ILayersContext>({} as ILayersContext);

export const LayersProvider = ({ children }: IPageProvider) => {
  const [layers, setLayers] = useState([]);
  const [tile, setTile] = useState(Tiles.CartoDB);
  //@ts-ignore
  function addNewLayer(layer) {
    setLayers(layer);
  }
  //@ts-ignore
  function addNewTile(tile) {
    setTile(tile);
  }

  function cleanCheckedLayers() {
    setLayers([]);
  }

  return (
    <LayersContext.Provider
      value={{ addNewLayer, layers, tile, addNewTile, cleanCheckedLayers }}
    >
      {children}
    </LayersContext.Provider>
  );
};

export function useLayers() {
  const context = useContext(LayersContext);

  if (!context) {
    throw new Error('usePageContext must be used within a PageProvider');
  }

  return context;
}

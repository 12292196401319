import { useLayers } from '../../../context/LayersContext';
import { WMSTileLayer } from 'react-leaflet';
import { layersDict } from '../../../global/constants/layersDict';

export function RenderSenarios() {
  const { layers } = useLayers();
  if (!layers) return null;

  return (
    <>
      {layersDict.map((obj) => {
        if (layers.includes(obj.title)) {
          return (
            <WMSTileLayer
              layers={`${obj.workspace}:${obj.layerName}`}
              url={`https://dev.geoserver.isitics.com/geoserver/${obj.workspace}/wms`}
              // params={{hello:'world'}} // <-- comment out this line to stop the map flickering when the button is pressed
              // maxZoom={6}
              transparent={true}
              format="image/png"
              // opacity={0.8}
              zIndex={200}
            />
          );
        }
      })}
    </>
  );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DETAIL_METADATA_ROUTE } from '../../../global/constants/routes/metadataConstants';
import {
  StyledItemContainer,
  StyledItemImg,
  StyledItemTextual,
  StyledItemTextualKeyValue,
  StyledItemTextualSummary,
  StyledItemTextualText,
  StyledItemTextualTextKey,
  StyledItemTextualTextValue,
  StyledItemTextualTextStatus,
  StyledItemTextualTitle,
  TextualBold,
} from './styles';

export type MetadataPreviewProps = {
  id: string;
  imagem: string;
  titulo: string;
  dataDivulgacao: string;
  resumo: string;
  responsavel: string;
  statusDesenvolvimento: string;
  categoriaTematica: string;
};

interface MetadataItemComponentProps {
  metadataItem: MetadataPreviewProps;
}

export const MetadataItemComponent: React.FC<MetadataItemComponentProps> = ({
  metadataItem,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(DETAIL_METADATA_ROUTE(metadataItem.id));
  };

  return (
    <StyledItemContainer onClick={handleClick}>
      <StyledItemImg src={metadataItem.imagem} alt={metadataItem.titulo} />
      <StyledItemTextual>
        <StyledItemTextualTitle>{metadataItem.titulo}</StyledItemTextualTitle>
        <StyledItemTextualText>
          <TextualBold>Responsável: {metadataItem.responsavel}</TextualBold>
        </StyledItemTextualText>
        <StyledItemTextualSummary>
          {metadataItem.resumo}
        </StyledItemTextualSummary>
        <StyledItemTextualKeyValue>
          <StyledItemTextualTextKey>
            Data de Divulgação
          </StyledItemTextualTextKey>
          <StyledItemTextualTextValue>
            {metadataItem.dataDivulgacao}
          </StyledItemTextualTextValue>
        </StyledItemTextualKeyValue>
        <StyledItemTextualKeyValue>
          <StyledItemTextualTextKey>Status</StyledItemTextualTextKey>
          {metadataItem.statusDesenvolvimento === 'Concluído' && (
            <StyledItemTextualTextStatus
              style={{ background: '#c8ff9d', border: '2px solid #c8ff9d' }}
            >
              {metadataItem.statusDesenvolvimento}
            </StyledItemTextualTextStatus>
          )}
          {metadataItem.statusDesenvolvimento === 'Em Andamento' && (
            <StyledItemTextualTextStatus
              style={{ background: '#FFECA4', border: '2px solid #FFECA4' }}
            >
              {metadataItem.statusDesenvolvimento}
            </StyledItemTextualTextStatus>
          )}
          {metadataItem.statusDesenvolvimento === 'Contínuo' && (
            <StyledItemTextualTextStatus
              style={{ background: '#D3B0FF', border: '2px solid #D3B0FF' }}
            >
              {metadataItem.statusDesenvolvimento}
            </StyledItemTextualTextStatus>
          )}
        </StyledItemTextualKeyValue>
      </StyledItemTextual>
    </StyledItemContainer>
  );
};

import styled, { css } from 'styled-components';

export const ModalContent = styled.div`
  position: absolute;
  top: 550px;
  left: 280px;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 350px;
  gap: 32px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 24px;
  padding: 20px;
  z-index: 1001;
  border-radius: 4px;
`;

export const ModalTitle = styled.p`
  display: flex;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.typography.size.title};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  padding-bottom: 10px;
`;

export const CleanLayersButton = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.red};
    background: none;
    border: none;
    font-family: ${theme.typography.fontFamily};
    font-size: 1.6rem;
    font-weight: 500;
    padding: 0.5rem;
    position: absolute;
    top: 1rem;
    right: 3rem;
    border-radius: 3px;

    &:hover {
      background-color: #0000000a;
    }
  `}
`;

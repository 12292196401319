import styled, { css } from 'styled-components';

interface ButtonProps {
  isActive: boolean;
}

export const ModalContent = styled.div`
  top: 370px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1001;
  overflow: auto;
`;

export const ModalTitle = styled.p`
  display: flex;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.typography.size.title};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
`;

export const DashboardButton = styled.button<ButtonProps>`
  ${({ isActive }) => css`
    color: ${isActive ? '#FFF' : '#9fc131'};
    background-color: ${isActive ? '#9fc131' : 'transparent'};
    border: 1px solid #9fc131;
    width: 170px;
    cursor: pointer;
    font-size: 11px;
    border-radius: 3px;
    font-weight: 600;
    padding: 10px;
  `}
`;

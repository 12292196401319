import React from 'react';
import {
  FormSubmitButton,
  StyledBackLink,
  StyledCardActions,
  StyledFieldsContainer,
  StyledForm,
} from './styles';
import { ReactComponent as ArrowLeftImage } from './assets/arrow-left.svg';
import { Card, CardContent } from '@mui/material';

interface FormContainerProps {
  onSubmit: () => void;
  onBack?: () => void;
  submitLabel?: string;
  children: React.ReactNode;
  formContainerFooter?: React.ReactNode;
}

export const FormContainer: React.FC<FormContainerProps> = ({
  onSubmit,
  onBack,
  submitLabel = 'Enviar',
  children,
  formContainerFooter,
}) => {
  return (
    <StyledForm onSubmit={onSubmit}>
      {onBack && (
        <StyledBackLink onClick={onBack}>
          <ArrowLeftImage style={{ width: '2.5rem', height: '2.5rem' }} />
        </StyledBackLink>
      )}
      <Card>
        <CardContent>
          <StyledFieldsContainer>{children}</StyledFieldsContainer>
        </CardContent>
        <StyledCardActions>
          <FormSubmitButton color="success" variant="contained" type="submit">
            {submitLabel}
          </FormSubmitButton>
          {formContainerFooter && formContainerFooter}
        </StyledCardActions>
      </Card>
    </StyledForm>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ruler {
  min-width: 88px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ruler-container {
  position: relative;
}
.ruler-label {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/RulerComponent/index.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".ruler {\n  min-width: 88px;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n.ruler-container {\n  position: relative;\n}\n.ruler-label {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 14px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

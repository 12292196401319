import { Autocomplete } from '@mui/material';
import L from 'leaflet';
import { useState } from 'react';
import { useMap } from 'react-leaflet';
import { SearchTextField } from './styles';
type Place = {
  place_id: string;
  licence: string;
  osm_type: string;
  osm_id: string;
  boundingbox: Array<string>;
  lat: string;
  lon: string;
  display_name: string;
  class: string;
  type: string;
  importance: number;
  icon: string;
};

export function SearchPlaceInput() {
  const NOMINATIM_BASE_URL = 'https://nominatim.openstreetmap.org/search?q';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [, /*searchTextInput,*/ setSearchTextInput] = useState<string>('');
  const [listPlace, setListPlace] = useState<Array<Place>>([]);
  const [selelectedPlace, setSelectedPlace] = useState<Place | null>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const map = useMap();

  function handleSelectedPlace(selectedPlace: Place | null) {
    if (selectedPlace !== null) {
      setSelectedPlace(selectedPlace);
      map.flyTo([Number(selectedPlace.lat), Number(selectedPlace.lon)], 10);
      const marker = L.marker([
        Number(selectedPlace.lat),
        Number(selectedPlace.lon),
      ]);
      var popUpContent = `${selectedPlace.display_name}`;
      marker
        .bindPopup(popUpContent, {
          maxWidth: 300,
          minWidth: 200,
          maxHeight: 1000,
        })
        .addTo(map);
    }
  }

  async function handleSearchPlaces(searchPlaceText: string) {
    setIsSearching(true);
    const params = {
      q: searchPlaceText,
      format: 'jsonv2',
    };

    const queryString = new URLSearchParams(params).toString();
    const requestOptions: RequestInit = {
      method: 'GET',
      redirect: 'follow',
    };
    fetch(`${NOMINATIM_BASE_URL}=${queryString}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setListPlace([]);
        const placeList = JSON.parse(result) as Array<any>;
        placeList.forEach((place) => {
          setListPlace((prev) => [...prev, place]);
        });
      })
      .finally(() => setIsSearching(false))
      .catch((err) => console.log('err: ', err));
  }

  return (
    <Autocomplete
      options={listPlace}
      getOptionLabel={(option) => option.display_name}
      value={selelectedPlace}
      loading={isSearching}
      loadingText={'Procurando uma Localização'}
      isOptionEqualToValue={(option, value) => {
        return option.place_id === value.place_id ? true : false;
      }}
      noOptionsText={'Não Encontrandos Nenhuma Localização'}
      onInputChange={(event, value) => {
        setSearchTextInput(value);
        handleSearchPlaces(value);
      }}
      onChange={(event, value) => {
        Promise.resolve(handleSelectedPlace(value));
      }}
      filterOptions={(options, params) => {
        const { inputValue } = params;
        setSearchTextInput(inputValue);
        return options.filter((option) => {
          return option.display_name
            .toLowerCase()
            .includes(inputValue.toLowerCase());
        });
      }}
      renderInput={(params) => {
        return (
          <SearchTextField
            {...params}
            fullWidth
            label="Procure uma Localização"
            InputProps={{
              ...params.InputProps,

              endAdornment: params.InputProps.endAdornment,
            }}
          />
        );
      }}
      id="search-location"
    />
  );
}

import styled from 'styled-components';
interface LegendContainerProps {
  expanded: boolean;
}
export const LegendContainer = styled.div.attrs({
  className: 'info-legend',
})<LegendContainerProps>`
  height: ${(props) => (props.expanded ? '380px' : '50px')};
  min-width: 140px;
  font: 10px Arial, Helvetica, sans-serif;
  padding: 6px 12px;
  background: white;
  border-radius: 5px;
  text-align: left;
  line-height: 18px;
  color: #555;
  overflow-y: auto;
  transition: height 1s ease;
`;

export const LegendHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
`;

export const LegendTitle = styled.h4`
  text-align: center;
  font-size: 15px;
  padding-top: 8px;
  font-weight: 600;
`;

export const LegendLabel = styled.h5`
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

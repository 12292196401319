import React from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import RouteIcon from '@mui/icons-material/Route';
import DrawIcon from '@mui/icons-material/Create';
import PrintIcon from '@mui/icons-material/Print';
import RulerComponent from '../RulerComponent';
import './index.css';

interface RulerModalProps {
  show: boolean;
  onClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  leftPosition: string;
}

export function RulerModal({ show, onClose, leftPosition }: RulerModalProps) {
  return (
    <div
      className={`modal-container ${show ? 'show' : 'hide'}`}
      style={{ left: leftPosition, transition: 'left 0.3s ease' }}
    >
      <div className="modal-content">
        <div className="modal-title">Ferramentas do Mapa</div>
        <div
          style={{
            position: 'relative',
            paddingTop: '8px',
            display: 'flex',
            gap: '16px',
            alignItems: 'flex-start',
          }}
        >
          <RulerComponent
            iconLabel="Localização"
            icon={PlaceIcon}
            rulerAction={'LOCATION'}
          />
          <RulerComponent
            iconLabel="Medir"
            icon={RouteIcon}
            rulerAction={'MEASURE'}
          />
          <RulerComponent
            iconLabel="Desenhar"
            icon={DrawIcon}
            rulerAction="DRAW"
          />
          <RulerComponent
            iconLabel="Imprimir"
            icon={PrintIcon}
            rulerAction="PRINT"
          />
        </div>
      </div>
    </div>
  );
}

export default RulerModal;

import AccountCircle from '@mui/icons-material/AccountCircle';
import { IconButton, Button } from '@mui/material';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { ApiLogout } from '../../../services/api';
import { AuthContext } from '../../../context/AuthContext';
import { AlertContext } from '../../../context/AlertContext';
import { LOGIN_ROUTE } from '../../../global/constants/routes/defaultConstants';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledMenuButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.3rem 2rem;
  cursor: pointer;

  svg {
    width: 2.2rem;
    height: 2.2rem;
  }

  label {
    cursor: pointer;
    line-height: 1.3125rem;
    font-size: 1.3rem;
    letter-spacing: 0.125rem;
    color: ${({ theme }) => theme.colors.grayLight};
  }

  &:hover {
    label {
      text-decoration: underline;
    }
  }
`;

const LogoutButtonContainer = styled.div`
  position: absolute;
  right: 2rem;
  top: 5rem;
`;

export const ProfileMenu: React.FC = () => {
  const { logout } = useContext(AuthContext);
  const { setAlertMessage } = useContext(AlertContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const logoutButtonRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const { message } = await ApiLogout();
      await setAlertMessage('success', message);
      logout();
      navigate(LOGIN_ROUTE);
    } catch (error) {
      setAlertMessage('error', (error as Error).message);
    }
    handleClose();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        logoutButtonRef.current &&
        !logoutButtonRef.current.contains(event.target as Node) &&
        anchorEl &&
        !anchorEl.contains(event.target as Node)
      ) {
        handleClose();
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, anchorEl]);

  return (
    <>
      <StyledMenuButton onClick={handleClick}>
        <IconButton>
          <AccountCircle />
        </IconButton>
        <label>PERFIL</label>
      </StyledMenuButton>
      {open && (
        <LogoutButtonContainer ref={logoutButtonRef}>
          <Button
            variant="contained"
            color="error"
            onClick={handleLogout}
            style={{ width: '100px' }}
          >
            Logout
          </Button>
        </LogoutButtonContainer>
      )}
    </>
  );
};

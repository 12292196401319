import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { ModalTitle } from './styles';

export default function MapLimitsModal() {
  return (
    <>
      <ModalTitle>Limites Administrativos</ModalTitle>
      <FormGroup sx={{ justifyContent: 'center' }}>
        <FormControlLabel
          control={<Checkbox />}
          label="Estados BRA"
          onChange={() => console.log('Estados BRA')}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="Pernambuco"
          onChange={() => console.log('Pernambuco')}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="Regiões Desenvolvimento PE"
          onChange={() => console.log('Regiões Desenvolvimento PE')}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="Municipios PE"
          onChange={() => console.log('Municipios PE')}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="Microrregiões PE"
          onChange={() => console.log('Microrregiões PE')}
        />
      </FormGroup>
    </>
  );
}

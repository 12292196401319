import { Modal } from '@mui/material';
import { CloseButton, ModalContent, ModalHeader } from './styles';
import MapTilesModal from './MapTilesModal';
import MapLimitsModal from './MapLimitsModal';

interface MapViewModalProps {
  show: boolean;
  onClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

export default function MapViewModal({ show, onClose }: MapViewModalProps) {
  return show ? (
    <div
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ModalContent>
        <ModalHeader>Visualização do Mapa</ModalHeader>
        <MapTilesModal />
        <MapLimitsModal />
      </ModalContent>
    </div>
  ) : null;
}

import DesignServicesIcon from '@mui/icons-material/DesignServicesOutlined';
import FileUploadIcon from '@mui/icons-material/UploadFileOutlined';
import ExpandMoreIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import ExpandLessIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined';
import { Toolbar } from '@mui/material';
import { SearchPlaceInput } from '../index';
import { IconContainer, IconWrapper } from './styles';
import ZoomControl from '../ZoomControl';

interface MapActionBarProps {
  handleToggleModal: () => void;
  showModal: boolean;
  handleToolModal: () => void;
  showToolModal: boolean;
  handleMiniMapModal: () => void;
  showMiniMapModal: boolean;
  handleUploadFileModal: () => void;
  showUploadFileModal: boolean;
  onPositionChange: (position: string) => void;
  onPositionChangeMiniMap: (position: string) => void;
}

export function MapActionBar({
  handleToggleModal,
  showModal,
  handleToolModal,
  showToolModal,
  handleUploadFileModal,
  showUploadFileModal,
  handleMiniMapModal,
  showMiniMapModal,
  onPositionChange,
  onPositionChangeMiniMap,
}: MapActionBarProps) {
  const modalLeftPosition = showModal ? '410px' : '0px';
  const modalLeftMiniMap = showModal ? '445px' : '20px';

  onPositionChange(modalLeftPosition);
  onPositionChangeMiniMap(modalLeftMiniMap);

  const toggleUploadFileModal = () => {
    handleUploadFileModal();
    if (!showUploadFileModal) {
      if (showToolModal) handleToolModal();
      if (showMiniMapModal) handleMiniMapModal();
    }
  };

  const toggleToolModal = () => {
    handleToolModal();
    if (!showToolModal) {
      if (showUploadFileModal) handleUploadFileModal();
      if (showMiniMapModal) handleMiniMapModal();
    }
  };

  const toggleMiniMapModal = () => {
    handleMiniMapModal();
    if (!showMiniMapModal) {
      if (showUploadFileModal) handleUploadFileModal();
      if (showToolModal) handleToolModal();
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          borderRadius: '4px',
          zIndex: '1002',
        }}
      >
        <SearchPlaceInput />
        <IconContainer
          style={{
            marginLeft: showModal ? '420px' : '0px',
            transition: 'margin-left 0.3s ease',
          }}
        >
          <IconWrapper onClick={handleToggleModal} aria-label="Toggle Modal">
            {showModal ? (
              <ExpandLessIcon fontSize="large" />
            ) : (
              <ExpandMoreIcon fontSize="large" />
            )}
          </IconWrapper>
          <IconWrapper
            className={showUploadFileModal ? 'active' : ''}
            onClick={toggleUploadFileModal}
          >
            <FileUploadIcon fontSize="large" />
          </IconWrapper>
          <IconWrapper
            className={showToolModal ? 'active' : ''}
            onClick={toggleToolModal}
          >
            <DesignServicesIcon fontSize="large" />
          </IconWrapper>
          <div onClick={(e) => e.stopPropagation()}>
            <ZoomControl />
          </div>
          <IconWrapper
            className={showMiniMapModal ? 'active' : ''}
            onClick={toggleMiniMapModal}
          >
            <LocationOnIcon fontSize="large" />
          </IconWrapper>
        </IconContainer>
      </Toolbar>
    </div>
  );
}

export default MapActionBar;

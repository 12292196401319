import { Modal, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CheckboxTreeView } from '../CheckboxTreeView';
import { ModalContent, ModalTitle, CleanLayersButton } from './styles';
import { geoLayers } from './layers';
import { useLayers } from '../../context/LayersContext';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

interface ScenariosModal {
  show: boolean;
  onClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

export default function ScenariosModal() {
  const { cleanCheckedLayers } = useLayers();

  return (
    <ModalContent>
      <ModalTitle>Cenários (GeoServer)</ModalTitle>
      <CheckboxTreeView data={geoLayers} />
      <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={cleanCheckedLayers}
          style={{
            color: 'red',
            borderColor: 'red',
            width: '170px',
            gap: '5px',
          }}
        >
          <DeleteOutlineOutlinedIcon fontSize="small" />
          Limpar Camadas
        </Button>
      </Box>
    </ModalContent>
  );
}

import { ReactNode, createContext, useContext, useState } from 'react';

type ModalFunction = 'LOCATION' | 'PRINT' | 'DRAW' | 'MEASURE' | null;

interface IRulerModalContext {
  isModalOpen: boolean;
  activeFunction: ModalFunction;
  openModal: () => void;
  closeModal: () => void;
  activeFunctionHandler: (func: ModalFunction) => void;
}

const RulerModalContext = createContext<IRulerModalContext | undefined>(
  undefined
);

interface IRulerModalProvider {
  children: ReactNode;
}

export const RulerModalProvider = ({ children }: IRulerModalProvider) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeFunction, setActiveFunction] = useState<ModalFunction>(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const activeFunctionHandler = (func: ModalFunction) => {
    setActiveFunction(func);
  };
  return (
    <RulerModalContext.Provider
      value={{
        isModalOpen,
        activeFunction,
        openModal,
        closeModal,
        activeFunctionHandler,
      }}
    >
      {children}
    </RulerModalContext.Provider>
  );
};
export const useRulerModalContext = (): IRulerModalContext => {
  const context = useContext(RulerModalContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return context;
};

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Router } from './Components/Router';
import { AlertProvider } from './context/AlertContext';
import { AuthProvider } from './context/AuthContext';
import { LayersProvider } from './context/LayersContext';
import { RulerModalProvider } from './context/RulerContext';
import { UploadedFilesProvider } from './context/UploadedFilesContext';
import GlobalStyle from './global/styles';
import theme from './global/styles/theme';

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <AlertProvider>
          <UploadedFilesProvider>
            <LayersProvider>
              <AuthProvider>
                <RulerModalProvider>
                  <BrowserRouter>
                    <Router />
                  </BrowserRouter>
                </RulerModalProvider>
              </AuthProvider>
            </LayersProvider>
          </UploadedFilesProvider>
        </AlertProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;

import React, { useContext } from 'react';
import { FormContainer, FormTextField, FormTitle } from '../../Components';
import { useNavigate, useParams } from 'react-router-dom';
import { LOGIN_ROUTE } from '../../global/constants/routes/defaultConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AlertContext } from '../../context/AlertContext';
import { ApiRecoveryPassword } from '../../services/api';
import { recoveryPasswordType } from '../../global/types/user';

const recoveryPasswordSchema = yup.object({
  password: yup.string().required('A senha é necessária'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'As senhas precisam ser iguais')
    .required('A confimação de senha é necessária'),
});

export const RecoveryPassword: React.FC = () => {
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const { token } = useParams<{ token?: string }>();

  const handleRecoveryPasswordSchema = async (values: recoveryPasswordType) => {
    try {
      if (token) {
        const { message } = await ApiRecoveryPassword(token, values);
        await setAlertMessage('success', message);
        navigate(LOGIN_ROUTE);
      }
    } catch (error) {
      setAlertMessage('error', (error as Error).message);
    }
  };

  const recoveryPasswordForm = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: recoveryPasswordSchema,
    onSubmit: handleRecoveryPasswordSchema,
  });

  const handleBackClick = () => {
    window.location.href = LOGIN_ROUTE;
  };

  return (
    <FormContainer
      onSubmit={recoveryPasswordForm.handleSubmit}
      onBack={handleBackClick}
      submitLabel="Confimar"
    >
      <FormTitle>Insira sua nova Senha</FormTitle>
      <FormTextField
        standartLabel="Senha"
        placeholder="******"
        type="password"
        name="password"
        value={recoveryPasswordForm.values.password}
        onChange={recoveryPasswordForm.handleChange}
        onBlur={recoveryPasswordForm.handleBlur}
        error={
          recoveryPasswordForm.touched.password &&
          Boolean(recoveryPasswordForm.errors.password)
        }
        helperText={
          recoveryPasswordForm.touched.password &&
          recoveryPasswordForm.errors.password
        }
        required
      />
      <FormTextField
        standartLabel="Confirmar senha"
        placeholder="Repita a senha de login."
        type="password"
        name="confirmPassword"
        value={recoveryPasswordForm.values.confirmPassword}
        onChange={recoveryPasswordForm.handleChange}
        onBlur={recoveryPasswordForm.handleBlur}
        error={
          recoveryPasswordForm.touched.confirmPassword &&
          Boolean(recoveryPasswordForm.errors.confirmPassword)
        }
        helperText={
          recoveryPasswordForm.touched.confirmPassword &&
          recoveryPasswordForm.errors.confirmPassword
        }
        required
      />
    </FormContainer>
  );
};

import {
  Box,
  Button,
  CardActions,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

export const FormCenteredBlackLink = styled(Link)`
  && {
    text-align: center;
    text-decoration: underline;
    color: #000000;
    height: 400;
    font-size: 1.125rem;
    line-height: 1.3rem;
  }
`;

export const FormRedLink = styled(Link)`
  && {
    color: #d71515;
    text-decoration: underline;
    height: 400;
    align-self: start;
    font-size: 1.125rem;
    line-height: 1.3rem;
  }
`;

export const FormTitle = styled(Typography)`
  && {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    variant: h4;
    font-weight: 600;
    font-size: 20px;
    align-self: center;
    line-height: 2.0525rem;
    font-family: 'Work Sans', sans-serif;
  }
`;

export const FormSubmitButton = styled(Button)`
  && {
    background-color: #9fc131;
    color: #ffffff;
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
    border-radius: 0.5rem;
    width: 100%;

    &:hover {
      background-color: #8eab29;
    }
  }
`;

export const StyledFormTextFieldContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledFormTextField = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      background-color: #f5f5f5; /* Cor de fundo padrão */

      /* Cor da borda padrão */
      fieldset {
        border-color: #f5f5f5;
      }

      /* Cor da borda ao passar o mouse */
      &:hover fieldset {
        border-color: #707070;
      }

      /* Cor da borda quando o input está em foco */
      &.Mui-focused fieldset {
        border-color: #9fc131;
      }
    }
    .MuiInputBase-input {
      font-size: 1.3rem;
      line-height: 1.2rem;
      font-family: 'Work Sans', sans-serif;
    }
    .MuiFormLabel-root {
      font-size: 1.1rem;
      line-height: 1.2rem;
      &.Mui-focused {
        color: #9fc131;
      }
    }
    .MuiFormHelperText-root {
      font-size: 1.1rem;
    }
  }
`;

export const StyledFormTextFieldLabel = styled(Typography)`
  && {
    color: #015c53;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-family: 'Work Sans', sans-serif;
  }
`;

// Form Container

export const StyledForm = styled.form`
  && {
    position: relative;
    border-radius: 0.5rem;
    width: 41rem;
    background-color: #ffffff;
  }
`;

export const StyledBackLink = styled(Link)`
  && {
    cursor: 'pointer';
    position: absolute;
    top: 0rem;
    left: -3.75rem;
  }
`;

export const StyledFieldsContainer = styled(Box)`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

export const StyledCardActions = styled(CardActions)`
  && {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-content: center;
  }
`;

import ExpandMoreIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import ExpandLessIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import { LegendContainer, LegendWrapper } from './styles';
import { Toolbar } from '@mui/material';

interface LegendActionBar {
  handleLegendModal: () => void;
  showLegendModal: boolean;
}

export default function LegendActionBar({
  handleLegendModal,
  showLegendModal,
}: LegendActionBar) {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        borderRadius: '4px',
        zIndex: '1004',
        marginRight: '20px',
      }}
    >
      <LegendContainer
        style={{
          marginRight: showLegendModal ? '535px' : '20px',
          transition: 'margin-left 0.3s ease',
        }}
      >
        <LegendWrapper onClick={handleLegendModal} aria-label="Legend Modal">
          {showLegendModal ? (
            <ExpandMoreIcon fontSize="large" />
          ) : (
            <ExpandLessIcon fontSize="large" />
          )}
        </LegendWrapper>
      </LegendContainer>
    </div>
  );
}

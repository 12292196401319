import DesignServicesIcon from '@mui/icons-material/DesignServices';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import LayersIcon from '@mui/icons-material/Layers';
import MapIcon from '@mui/icons-material/Map';
import PublicIcon from '@mui/icons-material/Public';
import { Toolbar } from '@mui/material';
import { SearchPlaceInput } from '../index';
import { IconContainer, IconWrapper } from './styles';
interface MapActionBarProps {
  handleGeoLayersModal: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
  showGeoLayersModal: boolean;
  handleToolModal: (value: boolean | ((preVar: boolean) => boolean)) => void;
  showToolModal: boolean;
  handleLayersModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  showLayersModal: boolean;
  handleMapViewModal: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
  showMapViewModal: boolean;
  showScenariosModal: boolean;
  handleScenariosView: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
  setShowUploadFileModal: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
  showUploadFileModal: boolean;
}

//@ts-ignore
export function MapActionBar({
  handleGeoLayersModal,
  showGeoLayersModal,
  handleToolModal,
  showToolModal,
  handleLayersModal,
  showLayersModal,
  handleMapViewModal,
  showMapViewModal,
  handleScenariosView,
  showScenariosModal,
  setShowUploadFileModal,
  showUploadFileModal,
}: MapActionBarProps) {
  // const { closeModal, isModalOpen, openModal } = useRulerModalContext();
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        borderRadius: '4px',
        zIndex: '1002',
      }}
    >
      <SearchPlaceInput />
      <IconContainer>
        <IconWrapper
          className={showGeoLayersModal ? 'active' : ''}
          onClick={() => handleGeoLayersModal(!showGeoLayersModal)}
        >
          <PublicIcon fontSize="large" />
        </IconWrapper>
        <IconWrapper
          className={showLayersModal ? 'active' : ''}
          onClick={() => handleLayersModal(!showLayersModal)}
        >
          <LayersIcon fontSize="large" />
        </IconWrapper>
        <IconWrapper
          className={showMapViewModal ? 'active' : ''}
          onClick={() => handleMapViewModal(!showMapViewModal)}
        >
          <MapIcon fontSize="large" />
        </IconWrapper>
        <IconWrapper 
          className={showToolModal ? 'active' : ''}
          onClick={() => handleToolModal(!showToolModal)}>
          <DesignServicesIcon fontSize="large" />
        </IconWrapper>
        <IconWrapper
          className={showUploadFileModal ? 'active' : ''}
          onClick={() => setShowUploadFileModal(!showUploadFileModal)}
        >
          <FileUploadIcon fontSize="large" />
        </IconWrapper>
        <IconWrapper 
          className={showScenariosModal ? 'active' : ''}
          onClick={() => handleScenariosView(!showScenariosModal)}>
          <FilterFramesIcon fontSize="large" />
        </IconWrapper>
      </IconContainer>
    </Toolbar>
  );
}

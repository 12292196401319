import {
  geoServerBaseUrl,
  geoServerLegendDefaultParams,
} from '../../services/geoServer';
import { Box } from '@mui/system';

interface LegendDataProps {
  layer: string;
  workspace: string;
}

export function LegendData({ layer, workspace }: LegendDataProps) {
  // https://docs.geoserver.org/main/en/user/services/wms/get_legend_graphic/index.html
  let url = `${geoServerBaseUrl}/${geoServerLegendDefaultParams.service}?`;
  url += `REQUEST=${geoServerLegendDefaultParams.request}&`;
  url += `VERSION=${geoServerLegendDefaultParams.version}&`;
  url += `FORMAT=${geoServerLegendDefaultParams.format}&`;
  url += `WIDTH=${geoServerLegendDefaultParams.width}&`;
  url += `HEIGHT=${geoServerLegendDefaultParams.height}&`;
  url += `LAYER=${workspace}:${layer}`;

  return (
    <Box sx={{ flexDirection: 'column' }}>
      <img src={url} />
    </Box>
  );
}

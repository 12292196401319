import React, { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { FormControlLabel } from '@mui/material';
import { useLayers } from '../../context/LayersContext';

import { StyledCheckbox, TreeViewContainer } from './styles';

export interface TreeNode {
  value: string;
  label: string;
  children?: TreeNode[];
}

// interface Props {
//   nodes: TreeNode[];
//   checked: string[];
//   expanded: string[];
//   setChecked: (checked: string[]) => void;
//   setExpanded: (expanded: string[]) => void;
//   setChannelNamesToDisplay: (channelNames: string[]) => void;
// }

function leafIds(node: TreeNode): string[] {
  return !node.children || node.children.length === 0
    ? [node.value]
    : node.children?.map(leafIds).flat();
}

export interface CheckboxTreeViewProps {
  data: (string | string[])[][];
}

export function CheckboxTreeView({ data }: CheckboxTreeViewProps) {
  // const classes = useCondensedCheckBoxStyles();
  const { addNewLayer, layers } = useLayers();
  let checked = layers;
  const setChecked = addNewLayer;
  // const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const nodes = data?.map(([type, names]) => ({
    value: type,
    label: type,
    //@ts-ignore
    children: names?.map((name) => ({ value: name, label: name })),
  }));

  const handleCheck = (node: TreeNode, newValue: boolean) => {
    //@ts-ignore
    const value = checked.includes(node.value);
    if (!node.children || node.children.length === 0) {
      if (value === newValue) return;
      setChecked(
        //@ts-ignore
        newValue
          ? [...checked, node.value]
          : checked.filter((id) => id !== node.value)
      );
    } else {
      const ids = leafIds(node);
      const remaining = checked.filter((id) => !ids.includes(id));
      //@ts-ignore
      setChecked(newValue ? [...remaining, ...ids] : remaining);
    }
  };

  function TreeNode({ node }: { node: TreeNode }) {
    //@ts-ignore
    const isChecked = leafIds(node).every((id) => checked.includes(id));
    const isIndeterminate =
      //@ts-ignore
      !isChecked && leafIds(node).some((id) => checked.includes(id));
    const onChange = () => {
      handleCheck(node, !isChecked);
    };

    return (
      <TreeItem
        key={node.value}
        nodeId={node.value}
        label={
          <FormControlLabel
            className={isChecked ? 'active' : ''}
            color="white"
            style={{ display: 'flex', alignItems: 'center' }}
            control={
              <StyledCheckbox
                checked={isChecked}
                onChange={onChange}
                onClick={(e) => e.stopPropagation()}
                indeterminate={isIndeterminate}
              />
            }
            label={node.label}
            onClick={(e) => e.stopPropagation()}
          />
        }
      >
        {node.children && <TreeNodes nodes={node.children} />}
      </TreeItem>
    );
  }

  function TreeNodes({ nodes }: { nodes: TreeNode[] }) {
    return (
      <>
        {nodes?.map((node) => (
          <TreeNode node={node} key={node.label} />
        ))}
      </>
    );
  }

  return (
    <TreeViewContainer
      defaultCollapseIcon={<ExpandLessIcon />}
      defaultExpandIcon={<ExpandMoreIcon />}
      defaultEndIcon={<ExpandLessIcon style={{ display: 'none' }} />}
      expanded={expanded}
      //@ts-ignore
      onNodeToggle={(_, nodes) => setExpanded(nodes)}
    >
      <TreeNodes
        //@ts-ignore
        nodes={nodes}
      />
    </TreeViewContainer>
  );
}

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { useMap } from 'react-leaflet';
export default function ZoomControl() {
  const map = useMap();

  function handleZoomIn() {
    map.setZoom(map.getZoom() + 1);
  }

  function handleZoomOut() {
    map.setZoom(map.getZoom() - 1);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        gap: 'auto',
      }}
    >
      <button
        style={{
          backgroundColor: 'white',
          color: 'black',
          border: '1px solid #D9D9D9',
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
          width: '40px',
          height: '40px',
          cursor: 'pointer',
        }}
        onClick={() => handleZoomIn()}
      >
        <ZoomInIcon sx={{ fontSize: '2.4rem' }} />
      </button>
      <button
        style={{
          backgroundColor: 'white',
          color: 'black',
          border: '1px solid #D9D9D9',
          borderBottomLeftRadius: '6px',
          borderBottomRightRadius: '6px',
          width: '40px',
          height: '40px',
          cursor: 'pointer',
        }}
        onClick={() => handleZoomOut()}
      >
        <ZoomOutIcon sx={{ fontSize: '2.4rem' }} />
      </button>
    </div>
  );
}
